@import "toolkit";

.page-order .step-5 {

	h2 { 
		margin-bottom: 22px !important; 

		&.payment-title {
			margin-bottom: 15px !important;
		}
	}


	p.payment {
		line-height: 15px;
    	margin: 0;
	}


	.form-group.radio {

		margin-bottom: 26px;

		.btn-group { margin-top: 10px; }
		.btn {
			padding: 45px 40px;
			margin: 0;
			input { width: 0; }
		}
	}

	.inner-wrapper { 
		width: 366px; 
		max-width: 100%;
		margin: 15px 0 60px;
	}

	.order-total {
		display: inline-block;
	    color: #000;
	    font-family: "Flama Light",sans-serif;
	    font-size: 25px;
	    line-height: 25px;
	    padding: 11px 0 10px;
	    text-transform: uppercase;
	    font-weight: normal;
	}

	.short.dropdown {
		max-width: 25% !important;
		clear: none !important;

		&.year{
			padding-top: 30px;
		}

		.bootstrap-select{
			.btn {
				margin-top: 0px !important;
				color: #999;
			}	
		}
	}

	.middle.cvv {
		width: 50% !important;
		padding-right: 70px;
		a {
			position: absolute;
		    right: 8px;
		    top: 36px;
		    cursor: pointer;
		}
	}

	.filter-option.pull-left {
		color: #999;
	}

	.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
	    width: 84px;
	}

	.submit { 
		display: block; 
		border-width: 1px;
		clear: both;
		.btn-group { 
			width: -moz-calc(100% - 240px);
			width: -webkit-calc(100% - 240px);
			width: calc(100% - 240px);
			.btn.checkbox {
				margin-top: 0 !important;
				span.icon { margin-top: 2px; }
				span.text {
					font-size: 15px;
					line-height: 20px;
				}
			}
		}
	}

	@include xs-only {

		.submit .btn-group { 
			width: 100%;
		}

		.form-group.radio .btn {
			width: 100%;
			margin: 10px 0;
			max-width: 338px;
		}

		.short.dropdown {
		    clear: none !important;
		    max-width: 50% !important;
		    padding-right: 5px;
		    &.year {
		    	padding-left: 5px;
		    	padding-right: 0;
		    }
		}

		.middle.cvv {
			max-width: 100% !important;
			width: 100% !important;
			clear: both;
		}

		.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    		width: 100%;
		}
	
	}
}
@import "toolkit";

.page-account {
	flex-grow: 1;

	a.log__out {
		position: absolute;
		top: -60px;
		left: 0px;
		background-color: #ccc;
		border: 0;
		color: #000;
		width: 110px;
		height: 50px;
		font-size: 16px;
		line-height: 50px;
		text-transform: uppercase;
		padding-bottom: 1px;
		text-align: center;
		text-transform: uppercase;
		text-decoration: none;
	}

	.content-with-sidebar {
		padding-top: 80px;
		position: relative;

		.form-section { 
			display: inline-block;
			margin: 0 0 0 50px;

			.small-container .form__title { margin-bottom: 25px; }

			form {
				margin: 0 auto;
				width: 366px;

				.form-group {				

					&.cancel, &.submit {
						max-width: 110px;

						button {
							max-width: 110px;
							width: 110px;
							margin-top: 23px;
						}
					}
				}
			}
		}

		.orders {
			display: inline-block;
			width: 590px;
			max-width: -moz-calc(100% - 408px);
			max-width: -webkit-calc(100% - 408px);
			max-width: calc(100% - 408px);
			margin: 0 0 0 35px;
			border: 1px solid #999;
			padding: 22px 30px 30px;

			h2 {
				font-family: "Flama Light", sans-serif;
				font-weight: normal;
				font-size: 25px;
				line-height: 25px;
				color: #000;
				text-transform: uppercase;
				margin: 0 0 23px;
				padding-right: 50px;
				position: relative;
				.order__now {
					position: absolute;
					right: 0;
					top: -12px;
					background-color: $color-red;
				    border: 0 none;
				    color: #fff !important;
				    font-size: 16px;
				    height: 50px;
				    line-height: 48px;
				    text-align: center;
				    text-decoration: none;
				    text-transform: uppercase;
				    display: inline-block;
				    width: 136px;
				    font-family: "Flama";
			    	-webkit-transition: all 0.2s ease 0s;
				    transition: all 0.2s ease 0s;
		    		&:hover {
		    			background-color: #c82127;
		    		}
				}
			}

			table {
				width: 100%;

				thead {
					background-color: $color-dark;
					color: #fff;
					font-family: "Flama Condensed Light";
					text-transform: uppercase;
					font-size: 25px;
					line-height: 56px;

					tr { border: 1px solid $color-dark; }

					th { 
						font-weight: 400; 
						text-align: left;

						&:first-child { padding-left: 22px; }

						&:last-child { 
							padding-right: 22px; 
							text-align: right;
						}
					}
				}

				tbody {
					
					tr { 
						border: 1px solid #999; 

						&.no-order td {
							padding: 30px;
							font-family: "Flama Condensed Light";
							text-align: center;
							font-size: 25px;
							line-height: 35px;
							text-transform: uppercase;
							color: #999;

							a { 
								color: $color-red; 
								text-decoration: none;
								&:hover { text-decoration: underline; }
							}
						}
					}

					td {
						line-height: 28px;
						padding: 10px 0;
						text-align: left;
						font-family: Arial;
						font-size: 15px;
						color: $color-dark;
						&:first-child { padding-left: 22px; }
						&:last-child { 
							padding-right: 22px; 
							text-align: right;
						}
					}
				}
			}
		}

		.sidebar {
			float: left;
			width: 372px;
			max-width: 100%;
			border: 1px solid #999;
			padding: 22px;
			font-family: Arial;
			font-size: 14px;
			margin-bottom: 30px;
		}

		.sidebar-widget {
			position: relative;
			clear: left;
			padding-bottom: 28px;

			&:last-child { padding-bottom: 0; }

			h2 {
				font-family: "Flama Light", sans-serif;
				font-weight: normal;
				font-size: 25px;
				line-height: 25px;
				color: #000;
				text-transform: uppercase;
				margin: 0 0 15px;
				padding-right: 50px;
			}

			.edit {
			  	position: absolute;
			  	right: 8px;
			  	top: -3px;
			  	width: 32px;
			  	height: 32px;
			  	background-color: transparent;

			  	.icon {
			  		font-size: 39px;
			  		color: #c82128;
			  	}
			}

			ul { list-style: none; }

			li { 
				padding: 2px 0; 
				clear: left;
				float: left;
				width: 100%;
			}

			label {
				clear: left;
				float: left;
				font-weight: bold;
			}

			span { float: right; }
		}
	}

	@media (max-width: 1024px ) {
		.content-with-sidebar .orders {
			float: right;
		}
	}

	@include xs-and-sm {
		
		.content-with-sidebar {

			.form-section {
				float: left;
				margin: 30px 0;
			}

			.orders {
				float: left;
				width: 100%;
				max-width: 100%;
				margin: 15px 0 30px;
			}

			.sidebar { clear: left; }
		}
	}

	@include xs-only {

		.content-with-sidebar {

			.sidebar-widget {
			
				h2 { font-size: 20px; }

				li { padding-bottom: 5px; }
				
				label { line-height: 16px; }
				
				span { clear: left; float: left; }
			}

			.orders {
				margin: 15px 0;
				padding: 0;
				border: 0;

				/* Force table to not be like tables anymore */
				table, thead, tbody, th, td, tr { 
					display: block; 
				}
				
				/* Hide table headers (but not display: none;, for accessibility) */
				thead tr { 
					position: absolute;
					top: -9999px;
					left: -9999px;
				}

				table tbody tr { 
					padding: 10px 0;
					border-bottom: 0;
					&:last-child { border: 1px solid #999; }
					&.no-order {
						padding: 0;

						td {
							padding: 30px !important;
							line-height: 30px !important;
							text-align: center !important;

							&:nth-of-type(1):before { 
								content: ""; 
								display: none;							
							}
						}
					}
				}
				
				td { 
					/* Behave  like a "row" */
					border: none;
					position: relative;
					padding: 9px 15px 9px 50% !important;
					text-align: left !important; 
					font-size: 14px;
					line-height: 18px !important;
					
					&:before { 
						/* Now like a table header */
						position: absolute;
						/* Top/left values mimic padding */
						top: 10px;
						left: 18px;
						width: 45%; 
						padding-right: 10px; 
						white-space: nowrap;
						font-family: "Flama Condensed Basic", "Flama";
						font-size: 17px;
					}
				}
				
				/*
				Label the data
				*/
				td:nth-of-type(1):before { content: "ORDER #"; }
				td:nth-of-type(2):before { content: "DATE"; }
				td:nth-of-type(3):before { content: "DETAILS"; }
				td:nth-of-type(4):before { content: "VALUE"; }
			}
		}
	}
}
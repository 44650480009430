@import "toolkit";

.page-order {
	flex-grow: 1;

		.step-1 {
			width: 100%;

			ul {
				list-style: none;
			}
				
			li { 
				border: 1px solid #999; 
				border-bottom: 0px;
				line-height: 28px;
				background-color: #ddd;
				text-align: left;
				font-family: Arial;
				font-size: 15px;
				color: $color-dark;
				width: 100%;
				display: flex;

				&.deleted {
					display: none !important;
				}

				&.no-delete-btn {
					.left__part { margin-left: 38px; }
				}

				&:first-child {
					border-top: 1px solid #999; 
				}

				.left__part { 
					padding: 12px 0px 0px 18px;
					text-align: left;
					flex: 1;

					.form-group { 
						margin-bottom: 0; 
						width: auto;
						&.error-val {
							.error-msg { display: block; }
							input { border: 1px solid $color-red !important; }
						}
						.error-msg {
							opacity: 1;
							white-space: pre;
    						width: auto;
    						position: absolute;
    						right: 0;
    						top: -37px;
    						&.type-2 { 
    							left: 0;
    							right: auto;
    						}
    						.msg-arrow { opacity: 1; }
						}
					}

					.remove__card {
						cursor: pointer;
					    display: inline-block;
					    height: 66px;
					    vertical-align: top;

						.remove {
							background-color: transparent;
						    height: 33px;
						    line-height: 23px;
						    margin: 0;
						    padding: 0 12px 0 0;
						
							span {
								color: #c82128 !important;
							    font-size: 38px;
							    margin-top: 0;
							    opacity: 1;
							}
						}	
					}

					.amount__wrapp {
						display: inline-block;
						margin-left: 8px;

						input {
							padding-left: 10px !important;
						}
					}

					.operation__wrapp {
						display: inline-block;

							.operation {
								width: 24px;
								height: 51px;
								text-align: center;
								font-size: 15px;
								font-family: "Flama";
								padding-top: 0px;
								display: table-cell;
								vertical-align: baseline;
								margin-right: 5px;
								font-weight: normal;
							}
					}

					.price__wrapp {
						display: inline-block;
						position: relative;
						padding-left: 6px;

						input {
							width: 100px;
						}
					}


					.dolar {
						position: absolute;
						top: 6px;
						left: 17px;
						font-size: 18px;
						font-family: Arial,sans-serif;
						font-weight: normal;
					}

					.input_description {
						display: block;
					}


					input {
						background-color: #ffffff;
					    border: 1px solid #999999;
					    border-radius: 0;
					    box-shadow: none;
					    color: #29292b;
					    font-family: Arial,sans-serif;
					    font-size: 18px;
					    font-weight: normal;
					    height: 40px;
					    width: 80px;
					    line-height: 20px;
					    padding: 10px 12px 10px 22px !important;
					    &[disabled] {
					    	background-color: #f1f1f1;
					    	color: rgba(41, 41, 43, 0.8);
					    }
					}

					span {
						margin: 6px 0 0 -2px;
						font-family: "Arial", sans-serif;
						font-size: 11px;	
						line-height: 11px;
						color: #29292b;
						opacity: 0.8;
					}

				}

				.right__part { 
					padding-right: 20px; 
					font-size: 30px;
					font-family: "Flama";
					text-align: right;
					align-self: center;

					.price {
						font-weight: normal;
						font-size: 30px;
						font-family: "Flama";
					}

					span {
						font-size: 25px;
						font-family: "Flama";
						padding-right: 10px;
					}
				}

				.dropdown-cell {
					width: 106px;
				}

			}

			.add_new  {
				border: 1px solid #999; 
				text-align: left;
				font-family: "Flama Light", sans-serif;
				text-transform: uppercase;
				font-size: 17px;
				line-height: 25px;
				color: #29292b;
				background-color: #fff;
				padding: 14px 20px;
				cursor: pointer;

				img {
					vertical-align: middle;
					margin-right: 6px;
				} 

				label {
					vertical-align: middle;
					font-weight: normal;
					margin-bottom: 0;
				}
			}

			.total {
				padding: 14px 24px;
				font-family: "Flama";
				text-align: right;
				font-size: 20px;
				line-height: 35px;
				text-transform: uppercase;
				color: #000;							
				background-color: #fff;
				border: 1px solid #999;
				border-top: 0px; 

				label {
					display: block;
					margin-bottom: 4px;
				}

				a { 
					background-color: #da291c;
				    border: 0 none;
				    color: #fff;
				    font-family: "Flama",sans-serif;
				    font-size: 16px;
				    font-weight: normal;
				    height: 50px;
				    line-height: 50px;
				    padding-bottom: 1px;
				    text-transform: uppercase;
				    width: 134px;
				    text-decoration: none;
				    text-align: center;
				    display: inline-block;
				}
			}

			.bottom-text {
				text-align: right;
				font-size: 15px;
				font-family: "Arial", sans-serif;

			a {
				text-decoration: none;
				color: $color-red;
			}

		}

		@include xs-only {

			li {
				display: block;

				.left__part {
					text-align: center;
					padding-left: 0;
				}

				.right__part {
					text-align: right;
					width: 270px;
					margin: 0 auto;
					align-self: inherit;
					padding-right: 0;
					padding-bottom: 10px;
					padding-top: 10px;
				}

			}
		}
		
	}
}
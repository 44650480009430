.form-section {
	text-align: center;
	margin-top: 60px;
	max-width: 100%;

	.small-container { margin: 0 auto; }
	
	.form__title{
		margin-bottom: 20px;

		h3{
			margin: 0;
			font-size: 25px;
			line-height: 25px;
			font-family: "Flama Light", sans-serif;
    		font-weight: normal;
			color: #000;
			text-transform: uppercase;
		}
	}

	p {
		font-size: 15px;
		line-height: 20px;
		font-family: Arial,sans-serif;
		font-weight: normal;
		color: $color-dark;

		a {
			color: $color-red;
			text-decoration: none;

			&:hover { text-decoration: underline; }
		}
	}

	form {
	    width: 280px;
	    max-width: 100%;
		margin: 25px auto 0;

		/* for field specific error */
		.error-msg {
	    	font-size: 12px; 
	    	line-height: 16px;
		    font-weight: normal;
	    	color: $color-red;
	    	display: none;
	    	padding: 5px 10px 7px;
	    	text-align: right;
	    	background-color: $color-red;
	    	color: #fff !important;
	    	&.show { 
	    		display: inline-block;
				position: absolute;
				right: 0;
				top: -6px; 
			}			
			&.type-2 .msg-arrow { 
				left: 14px;
				right: auto;
			}
			.msg-arrow {
				width: 0;
				height: 0;
				border-top: 8px solid $color-red;
				border-left: 16px solid transparent;
				position: absolute;
				bottom: -8px;
				right: 14px;
			}
	    }

		/* for top general error */
		> .error-msg {
			background-color: $color-red;
			color: #fff !important;
		    margin-bottom: 10px;
		    padding: 5px 10px 7px;
		    text-align: left;
		    display: block;
		}

		.form-group {
		    text-align: left;
		    width: 100%;
		    margin-bottom: 10px;
		    position: relative;
		    clear: both;

		    &.radio {
		    	/* for radio buttons with image */
		    	.btn-group { width: 100%; }		

		    	label { padding-left: 0; }

				.btn { 
					display: block; 
					padding: 10px 0;
					margin: 0 10px;
					font-family: Arial;
					font-size: 15px;
					color: $color-dark; 
					border-radius: 0;

					&.active { 
						border: 1px solid #c82128; 
						box-shadow: 0px 0px 2px 2px rgba(136, 136, 136, 0.4);
						background-color: #f5f5f5;
						.tick { display: block; }				
					}

					.edit, .remove, .tick {
					    background: #fff none repeat scroll 0 0;
					    border-radius: 50%;
					    bottom: -6px;
					    color: #c82128;
					    font-size: 42px;
					    height: 32px;
					    line-height: 34px;
					    margin: 0;
					    padding: 0;
					    position: absolute;
					    right: 36px;
					    width: 32px;
					    display: none;
					    .icon {
					    	display: block;
						    height: 34px;
						    left: -3px;
						    position: relative;
						    top: -2px;
						    width: 34px;
					    }
					}

					.remove, .tick { right: -4px; }

					img { max-width: 100%; }

					label { 
						display: block; 
						font-weight: bold;
						margin-top: 5px;
						line-height: 20px;
					}
					span { font-weight: normal; }

					input { width: 0; }
				}
		    }

			&.left { float: left; }
			&.right { float: right; clear: none; }

			&.long { max-width: 230px; }
			&.equal { max-width: 178px; }
			&.short { max-width: 126px; }

		    &.error {
		    	input, textarea { border-color: $color-red; }					    	
		    }

		    &.buttons {
		    	text-align: center;
		    	button { 
		    		display: inline-block; 
		    		margin: 15px 4px 0;
		    	}
		    }

		    > ul { display: none; }
		
			label {
				color: $color-dark;
			    font-size: 15px;
			    line-height: 30px;
			    font-weight: bold;
			    font-family: Arial,sans-serif;
			    margin: 0;
			    display: block;

			    .required { color: $color-red; }
			    .optional { 
			    	font-size: 12px; 
			    	font-weight: normal;
			    	margin-left: 5px;
			    }						    
			}

			.btn {
				-moz-user-select: none;
    			background-image: none;
				display: table;
				margin-top: 7px;

				&.checkbox {
					margin: 0;
					padding: 0;
					font-weight: normal;
					&.active span.icon { color: #000; }
					span.icon {
						border: 1px solid #999;
					    color: transparent;
					    display: inline-block;
					    font-size: 17px;
					    height: 20px;
					    line-height: 23px;
					    margin: -2px 10px 0 0;
					    padding: 0;
					    width: 21px;					    
					}
					span.text {
						color: $color-dark;
					    display: table-cell;
					    font-family: Arial,sans-serif;
					    font-size: 13px;
					    font-weight: normal;
					    line-height: 17px;
					    margin: 0;
					    padding-left: 5px;
					    text-align: left;
					    white-space: pre-wrap;
					}
				}
			
				input[type="checkbox"] {
					width: 20px;
					height: 20px;
					display: table-cell;
				}
			}

			input, textarea {
				width: 100%;
				background-color: #ffffff;
			    border: 1px solid #999999;
			    border-radius: 0px;
			    box-shadow: none;
			    font-family: Arial,sans-serif;
			    color: $color-dark;
			    font-size: 14px;
			    font-weight: normal;
			    height: 40px;
			    line-height: 20px;
			    padding: 10px 15px;
			    &[disabled] {
			    	background-color: #f1f1f1;
			    	color: rgba(41, 41, 43, 0.8);
			    }
			}

			.bootstrap-select {
				&.btn-group .dropdown-menu { 
					border-color: #999999;
				    border-radius: 0;
				    margin: -1px 0 0;
				    padding: 0;
					width: 100%;
					&.inner > li {
					    height: auto;
					    line-height: 14px;
					    padding: 0;
					}
					> li > a {
					    font-family: Arial;
					    font-size: 15px;
					    line-height: 18px;
					    padding: 10px 15px;
					    white-space: nowrap;
					    span.text {
						    max-width: 100%;
						    white-space: pre-line;
						}
					}
				}
				&.custom + .dropdown-custom-wrapper { display: block; }
				.dropdown-toggle:focus {
					outline: none !important;
	    			outline-offset: 0 !important;
				}
				.btn {
					width: 100%;
					background-color: #ffffff;
				    border: 1px solid #999999;
				    border-radius: 0px;
				    box-shadow: none;
				    font-family: Arial,sans-serif;
				    color: $color-dark;
				    font-size: 15px;
				    font-weight: normal;
				    height: 40px;
				    line-height: 20px;
				    padding: 10px 15px;
				    text-transform: inherit;
				}
				&+ .dropdown-custom-wrapper { display: none; }				
			}

			.dropdown-custom-counter {
				font-size: 13px; 
				color: #858585;
				font-weight: normal;
			}

			textarea { 
				min-height: 74px; 
				max-width: 100%;
				min-width: 100%;
			}

			.info { 
		    	font-size: 12px; 
		    	line-height: 16px;
		    	font-weight: normal;
		    	display: block;
		    	margin-top: 2px;
		    	&.link { 
		    		color: $color-red; 
		    		text-decoration: none;
		    	}
		    }

		    select { display: none; }

		    > button {
				background-color: #DA291C;
				color: #fff;
				border: 0;
				font-size: 16px;
				line-height: 25px;
				font-family: "Flama Light", sans-serif;
				font-weight: normal;
				width: 109px;
				height: 50px;
				text-transform: uppercase;
				padding-bottom: 1px;
				display: block;
				margin: 15px auto 0;
				&:hover {
					background-color: #C82127;
				}

				&.gray {
					background-color: #ccc;
					color: #000;
					&:hover {
						background-color: #999;
					}
				}

				&[disabled] {
					background-color: transparent !important;
					border: 1px solid #cccccc !important;
					color: #cccccc !important;
				}
			}
		}

		.captcha-msg {
			font-family: Arial,sans-serif;
		    color: $color-dark;
		    font-size: 12px;
		    text-align: left;
		    display: block;
		}
		.g-recaptcha {
			margin: 0 auto;
			width: 100%;
			margin-top: 20px;

			.rc-anchor{ width: 100% !important; }
		}

		.submit {

			.form-group { margin-bottom: 0; }

			.btn-group { float: left; }

			.btn { margin-top: 8px !important; }

			a {
				line-height: 45px;
				margin-left: 5px;
				color: $color-red;
			}

			button { float: right; margin: 0; width: auto; }
		}
	}

	@include xs-only {
		
		form .form-group {
			
			&.long, &.equal, &.short { max-width: 100%; }
		}
	}
}
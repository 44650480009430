@font-face {
    font-family: "Flama";
    font-style: normal;
    src: url('../fonts/Flama-Book.eot');
    src: url('../fonts/Flama-Book.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Flama-Book.woff2') format('woff2'),
         url('../fonts/Flama-Book.woff') format('woff'),
         url('../fonts/Flama-Book.ttf') format('truetype'),
         url('../fonts/Flama-Book.svg#flamabook') format('svg');
}
@font-face {
    font-family: "Flama Light";
    font-style: normal;
    src: url('../fonts/Flama-Light.eot');
    src: url('../fonts/Flama-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Flama-Light.woff2') format('woff2'),
         url('../fonts/Flama-Light.woff') format('woff'),
         url('../fonts/Flama-Light.ttf') format('truetype'),
         url('../fonts/Flama-Light.svg#flamabook') format('svg');
}
@font-face {
    font-family: "Flama Condensed Light";
    font-style: normal;
    src: url('../fonts/FlamaCondensed-Light.eot');
    src: url('../fonts/FlamaCondensed-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/FlamaCondensed-Light.woff2') format('woff2'),
         url('../fonts/FlamaCondensed-Light.woff') format('woff'),
         url('../fonts/FlamaCondensed-Light.ttf') format('truetype'),
         url('../fonts/FlamaCondensed-Light.svg#flamabook') format('svg');
}
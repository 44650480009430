@import "toolkit";

.header {
	background: #000 none repeat scroll 0 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 300;

	.logo svg {
		height: 6.5rem;
		width: 6.5rem;
		display: inline-block;
		vertical-align: middle;
		use { fill: #da291c; }
	}

	.nav {
	    background: transparent none repeat scroll 0 0;
	    border-left: 0 none;
	    overflow: visible;
	    position: static;
	    right: auto;
	    top: auto;
	    transform: none;
	    -webkit-transform: none;
	    -ms-transform: none;
	    visibility: visible;
	    width: auto;
	    -webkit-flex-grow: 1;
  		-ms-flex-grow: 1 1 auto;
	    flex-grow: 1;
	    height: 100%;
	    text-align: right;

	    .nav__bonus {
		    position: absolute;
		    right: 0;
		    top: 0;
		    z-index: 1;
		}

		.nav__bottom {
		    margin-top: 2.0625rem;
		    z-index: 1;
		    overflow: visible;
    		white-space: nowrap;

		    .nav__item {
		    	border-right: 0 !important;
		    	padding: 0;
		    	margin: 0;

		    	&:last-child .nav__link { padding-right: 0.3125rem; }

		    	.nav__link { 
		    		color: #fff; 
		    		line-height: inherit;
				    margin: 0;
				    font-size: 0.75rem;
    				padding: 0.5625rem 0.3125rem;

		    		&:hover {
    					background: #da291c none repeat scroll 0 0;
					}
		    	}
		    }
		}

		.nav__item {
			display: inline-block;
			position: relative;
			margin: 0.8125rem 0;
		}

		.nav__link {
			font-family: sans-serif;
		    font-size: 0.75rem;
		    line-height: 0.75rem;
		    padding: 0 0.625rem;
		    color: #999;
		    align-items: center;
			display: flex;
			text-transform: uppercase;
			text-align: left;
		    text-decoration: none;
	    	-webkit-transition: all 0.2s ease 0s;
		    transition: all 0.2s ease 0s;
		    -ms-transition: all 0.2s ease 0s;
		    width: 100%;
			
			&:hover, &:focus, &.selected { color:#fff; }
		}
	}

	.header__top-bar {
		.container {
			display: flex;
			align-items: center;
		    height: 4.6875rem;
		}

		.nav__item {
			&:not(:last-child) {
			    border-right: 1px solid #999;
			}
			&:last-child .nav__link { padding-right: 0; }
		}
	}

	.header__bottom-bar {
	    background-color: #28282a;
	    -webkit-transition: left .2s, right .2s;
	    transition: left .2s, right .2s;
	    position: relative;
	    right: 0;
	    left: 0;

		.container {
			display: flex;
			align-items: center;
		    height: 3.9375rem;
		}

		.nav__item { 
	    	height: 2.3125rem;
	    	float: left;

	    	.nav__link {
	    		font-family: "Flama Light", sans-serif;
		    	font-size: 0.81rem;
		    	color: #fff;
		    	display: block;
    			line-height: 35px;
	    	}
	    }

		.nav__left .nav__item { 
	    	border: 1px solid #fff;

	    	&:last-child .nav__link { padding-right: 1.25rem; }

	    	.nav__link { 
	    		padding: 0 1.25rem; 
		    	-webkit-transition: all 0.2s ease 0s;
			    transition: all 0.2s ease 0s;
	    		&:hover {
	    			background: #fff;
	    			color: #28282a;
	    		}
	    	}	    
		}

		.loggedin__user { 

			float: left;
			margin-top: 20px;
			color: #fff;
			font-size: 15px;
			font-family: "Flama Light", sans-serif;
			cursor: pointer;

			img {
				margin-right: 5px;
				vertical-align: text-bottom;
			}

			&.hide {
				display: none;
			}

			a {
				color: #fff;
				text-decoration: none;
			}

			a:hover {
				color: $color-red;
				text-decoration: none;
			}

			label {
				cursor: pointer;
			}
		}

		.nav__right {
		    float: right;
		    z-index: 1;
		    margin-right: -0.6375rem;

		    .nav__item .nav__link { 
		    	padding: 0 0.6375rem; 
		    	&:hover {
		    		background-color: $color-red;
		    	}
		    }		    
		}
	}

	.nav-trigger {
		display: none;
		border: 1px solid #fff;
	    border-width: 1px 0;
	    color: #fff;
	    font-size: 0.75rem;
	    margin: 0 0 0 1.25rem;
	    padding: 0;
	    text-transform: uppercase;
	    z-index: 3;
	    background-color: transparent;
	    height: 21px;
	    line-height: 21px;
	}

	@media (min-width: #{$screen-lg-min}) and (max-width: 1100px) {

		.header__bottom-bar {
			.nav__item .nav__link { font-size: 0.75rem; }
			.nav__right .nav__item .nav__link { padding: 0 0.5rem; }
		}
	}

    @include xs-and-sm {

    	.logo svg {
			height: 1.5rem;
			width: 5rem;
		}

    	.header__top-bar {
    		.container {
    			height: 3.4375rem;
    		}

    		nav { display: none; }
    	}

    	.header__bottom-bar {
	    	.nav-trigger { display: block; }

			.nav__right {
				background: #28282a;
				position: fixed;
				top: 3.4375rem;
				right: 0;
				height: 100%;
				overflow: auto;
				-webkit-overflow-scrolling: touch;
				z-index: 5;
				margin: 0;
				list-style: none;
				width: 260px;
				border-left: 1px solid #999;
				-webkit-transform: translateX(260px);
				-ms-transform: translateX(260px);
				transform: translateX(260px);
				-webkit-transition: -webkit-transform .2s;
				transition: transform .2s;
				-webkit-box-flex: 1;
				-webkit-flex-grow: 1;
				-ms-flex-positive: 1;
				flex-grow: 1;
				text-align: right;

				.nav__item {
				    border-bottom: 1px solid #999;
				    display: block;
			        float: none;
				    height: auto;
				    margin: 0;
				    
				    .nav__link { 
				    	font-size: 0.875rem;
				    	padding: 0.625rem;
				    	line-height: inherit;
				    }
				}
			}

			.nav__left .nav__item {
				&:last-child .nav__link { padding-right: 1rem; }
				.nav__link { 
					font-size: 0.9375rem; 
					padding: 0 1rem;
				}
			}
		}
	}
}
@import "toolkit";

.page-login {
	flex-grow: 1;

	.small-container{ max-width: 600px; }

	@include xs-and-sm {
		margin-bottom: 70px;
	}	
}
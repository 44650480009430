@import "toolkit";


.page-order {
	flex-grow: 1;

	.content-with-sidebar {

		.tabs.one-tab {

			ul { 

				margin-bottom: 20px !important;

				li.completed::before {
				    font-size: 22px;
				    left: 50% !important;
				    margin-left: -120px;
				    position: absolute;
				    top: 30px;
				}


				// smaller-desktop
				@media (max-width: 1280px) {	
		   			li {
		   				font-size: 28px !important;
		   				position: relative !important;

		   				&.completed {

							&:before {
								font-size: 22px !important;
							    left: 50% !important;
							    margin-left: -122px !important;
							    top: 29px !important;
							    position: absolute !important;
							}
						}

		   			}
				}

				@include xs-only {
					li {
					    font-size: 20px !important;

					    &.completed {

							&:before {
								display: inline !important;
							    margin-left: -102px !important;
							    top: 11px !important;
							}
						}
						span{ 
						display: block !important;
						}
					}
				}
			}
		}


		.step-6 {
			width: 100%;

			h2 { margin-bottom: 12px !important;}

			.gray {
				float:right;
				margin-bottom: 15px;
				margin-right: 0;
				&.left { 
					float: left; 
					margin-left: 0;
				}
			}
			.bottom-text .gray { margin-top: 15px; }

			ul { 
				list-style: none; 
				clear: both;
			}
				
			li { 
				border: 1px solid #999; 
				border-bottom: 0px;
				line-height: 25px;
				background-color: #fff;
				text-align: left;
				font-family: Arial;
				font-size: 15px;
				color: $color-dark;
				width: 100%;
				display: block;
				padding: 0;

				&:last-child {
					.card-overview {
						padding-bottom: 10px;
					}
				}


				.card-overview {

					padding: 22px 10px 24px 30px;

					&.left-part {
						float: left;
						width: 50%;
						padding-bottom: 18px;
						border-right: 1px solid #999;
						margin-right: -1px;

						label {
						    min-width: 125px !important;
						}
						&.tax-wrapp {
							border-right: 0px !important;
						}
					}

					&.right-part {
						float: left;
						width: 50%;
						border-left: 1px solid #999;
						padding-bottom: 18px;

						.data-row {
							margin-bottom: 0px;
						}
						&.tax-wrapp {
							border-left: 0px !important;
							padding-right: 5px;
							float: right !important;
							img { 
								float: right; 
								// smaller-desktop
								@media (max-width: 1280px) {	
						   			max-width: 280px;
								}
								// tablet
								@media (max-width: 1060px) {	
						   			max-width: 100%;
								}
							}
						}
					}

					.card-details {
						margin-bottom: 15px;
						&:last-child { margin-bottom: 0; }
					}

					a {
					    color: #c81218;
					    text-decoration: none;
					}

					p { 
 						font-style: italic;
					  	font-size: 13px;
					  	line-height: 18px;
					  	margin-bottom: 10px;
					  	margin-top: 7px;
					}

					&.thank-you-message {
						padding-bottom: 15px;
						h2 {
							font-size: 15px;
							font-weight: bold;
							font-family: Arial;
							text-transform: inherit;
						}
					}
				}


				.delivery {

					.shipping-info {
						float: left;
					}
				}

				.data-row {
					span { word-wrap: break-word; }

					.data-wrapp {	

						label {
							font-size: 15px;
						    font-weight: normal !important;
						    line-height: 15px;
						    margin-bottom: 0 !important;
						    min-width: 145px;
						}	
						span {
							display: inline-block;
						}
					}	
				}


				table {
					border: 0 !important;
					background-color: #fff !important;

					th {
						text-align: right;

						&:first-child {
							text-align: left;
						}
						&:last-child {
							padding-right: 25px !important;
						}
					}

					tr {
						border: 0 !important;
						background-color: #fff !important;

						&:last-child {
							td {
								padding-right: 28px !important;
								padding-top: 12px !important;
							}

						}
					}
					
					td {
						border: 0 !important;
						background-color: #fff !important;
						padding-left: 0 !important;
						text-align: right !important;
						padding-right: 0px !important;
						padding: 0 !important;
						line-height: 24px !important;

						&:first-child {
							text-align: left !important;
							max-width: 210px !important;
						}

						&:nth-child(3) {
							min-width: 100px !important;
						}

						&:last-child {
							padding-right: 16px !important;
							font-size: 15px !important;

							span {
								font-size: 15px !important;
							    font-weight: normal !important;
							    line-height: 15px !important;
							}

							label {
								font-size: 15px !important;
								line-height: 15px !important;
								margin-bottom: 0px !important;
							    min-width: 60px;
							}
						}

						.data-row {
						    margin-bottom: 0px !important;
						}
					}
				}

				&:last-child {
					border-bottom: 1px solid #999; 
				}
			}

			@include xs-print-only {

				li {
					display: block !important;

					.card-overview {
						padding-left: 20px;
						padding-right: 20px;
						font-size: 14px !important;

						h2 {
							font-size: 20px !important;
						}

						label {
							font-size: 14px !important;
						}

						&.left-part {
							width: 100% !important;
							display: block !important;
							border-right: 0;
							margin-right: 0;

							.shipping-info {
								.data-wrapp {
									span {
										display: block !important;
									}
								}
							}
						} 
						&.right-part {
							width: 100% !important;
							display: block !important;
							border-left: 0px !important;
							border-top: 1px solid #999 !important;
							&.tax-wrapp {
								float: left;
								padding-left: 5px;
								img{float: left;}
							}
							&.no-border-top {
								border-top: 0 !important;
							}
						} 
						&.thank-you-message {
							h2 { font-size: 15px !important;}
							p { font-size: 12px !important; }
						}

						.card-details label {							
							display: block;
							line-height: 20px;
						}
					}
				}

				.gray {
					margin: 15px 0 10px !important;
					padding: 0 23px 1px !important;
				}

				/* Force table to not be like tables anymore */
				table, thead, tbody, th, td, tr { 
					display: block; 
				}
				
				/* Hide table headers (but not display: none;, for accessibility) */
				thead tr { 
					position: absolute;
					top: -9999px;
					left: -9999px;
				}

				table tbody tr { 
					padding: 10px 0;
					border-bottom: 1px solid #999 !important;
					&:last-child { 
						border: 1px solid #999; 
						border-bottom: 0px solid #999 !important;
						padding-bottom: 0 !important;

						td {
							&:last-child {
								margin-top: -40px !important;

								.data-row {

									padding-left: 53% !important;
	    							text-align: left !important;

	    							label {
	    								float: right !important;
	    							}

									span {
										text-align: left !important;
										padding-right: 10px !important;
									}

									&:before { content: "" !important; }

								}
							}
							&.summary {
								.data-row {
									padding-left: 0 !important;
									text-align: right !important;

	    							label {
	    								float: right !important;
	    							}

									span {
										text-align: right !important;
									}

								}
							}
						}


					}

					td {

						font-size: 14px !important;

						&:first-child {
							max-width: 100% !important;
						}

						&:last-child {
							padding-right: 0px !important;

							span {
								padding-right: 0px !important;
							}
						}
					

						.data-row{
							padding: 30px !important;
							line-height: 30px !important;
							text-align: right !important;
							border: none;
							position: relative;
							padding: 3px 0 3px 40% !important;
							text-align: left !important; 
							font-size: 14px !important;
							line-height: 18px !important;
							text-align: right !important;
						
							&:before { 
								/* Now like a table header */
								position: absolute;
								/* Top/left values mimic padding */
								top: 0;
								left: 2px;
								width: 45%; 
								padding-right: 10px; 
								white-space: nowrap;
								font-family: "Flama Condensed Basic", "Flama";
								font-size: 14px;
								font-weight: bold;
								text-align: left !important;
							}
						}	

					}
				}
				
				/*
				Label the data
				*/
				td:nth-of-type(1) .data-row:before { content: "Item Name"; }
				td:nth-of-type(2) .data-row:before { content: "Price"; }
				td:nth-of-type(3) .data-row:before { content: "Quantity"; }
				td:nth-of-type(4) .data-row:before { content: "Total"; }

				.shipping-info {
					.data-wrapp {
						span {
							display: block !important;
						}
					}
				}
			}
		
		}
	}
}


@include xs-and-sm { 

	footer {
		margin-top: 75px !important;
	}
}

@media print and (color) {
   * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
   }
}


@media print
{		

	body {
		overflow: visible !important;
		overflow-y:scroll !important;
		width: 100% !important;
	}	

	.page-content {
		display: block !important;
	}

	.gift-cards {
		width: 100% !important;
		max-width: 100% !important;

	}

	.page-break {
		display: block;
		page-break-before: always !important;
		width: 100% !important;
	}

	.print-page {
		padding-top: 75px !important;
		visibility: visible !important;
		overflow: visible !important;  
	}

    header {
    	position: absolute !important; 
    	top: 0 !important;
    	left: 0 !important;
    }

	.noprint {
		display: none !important; 
	}	

	.page-content {
		padding-top: 0 !important;
	}

	.page-order .content-with-sidebar {
		.gift-cards h2 {
			font-size: 20px !important;
		}
		.step-6 li {
			line-height: 20px !important;
			font-size: 12px !important;
			min-width: 100px !important;
			.data-row {
				span, label, .data-wrapp label {
					line-height: 20px !important;
					font-size: 12px !important;
					min-width: 100px !important;
				}
			}
			.card-overview.left-part {
				border-right: 0 !important;
				margin-right: 0 !important;
			}
		}
	}
}


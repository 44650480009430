@import "toolkit";

.page-contact {
	flex-grow: 1;

	.text-section {
		p{
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Arial";
			color: #29292b;
		}
	}

	.form-section{
		margin-top: 0px;

		form {
			margin: 0 !important;

			button{ margin: 2px 0 !important; }

			textarea {
			    resize: none !important;
			    height: 160px !important;
			}
		}
	}	

}
.fileUpload {
    position: relative;
    overflow: hidden;

  input.upload {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      font-size: 20px;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
  }
}

.fd-zone {
  position: relative;
  overflow: hidden;
  width: 15em;
  margin: 0 auto;
  text-align: center;
}

/* Hides <input type="file"> while simulating "Browse" button: */
.fd-file {
  opacity: 0;
  font-size: 118px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
  padding: 0;
  margin: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  font-family: sans-serif;
}

/***
  With .over you can provide feedback when user drags a file over the drop zone:
 ***
  .fd-zone.over { border-color: maroon; background: #eee; }
*/


.fd-zone {
	width: 228px;
  margin-bottom: 10px;
}
.fd-zone.error {
	
}

.fd-zone:hover,
.fd-zone:focus {
	
}
.fd-file {
	width: 100%;
	height: 144px;
}
.fd-zone form {
	margin: 0 !important;
}
.white-box .fd-zone p.font-large {
	line-height: 96px; 
	margin: 0px;    
	position: relative;
    z-index: 10;
}
.white-box .fd-zone.error p.font-large {
	color: #ce624a;
}
.loading .progress { display: block; }
.progress {
  display: none;
  position: absolute;    
  top: 24px;
  left: 31px;
  height: 50px;
  width: 172px;
  background-color: #ccc;
  color: #fff;

  span {
  	background-color: #000;
  	display: block;
  	height: 100%;
  	width: 0;
  	transition: all 0.2s ease 0s;
  	-webkit-transition: all 0.2s ease 0s;
  	-moz-transition: all 0.2s ease 0s;
  	-ms-transition: all 0.2s ease 0s;
  	-o-transition: all 0.2s ease 0s;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-family: "Flama Basic", sans-serif;
    font-size: 16px;
    font-weight: normal;
  }
}
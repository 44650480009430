@import "fonts";
@import "customFonts";
@import "magnific-popup";
@import "toolkit";
@import "bootstrap.min";
@import "bootstrap-select.min";
@import "filedrop";
@import "jcrop.min";

/****** general ******/
	html, body {
	    font-size: 16px;
	    margin: 0;
	    font-family: "Flama", sans-serif;
	    font-style: normal;
    	font-weight: 400;
    	line-height: 140%;
	}

	*, *:before, *:after { box-sizing:border-box; }

	*::-moz-focus-inner { border: 0; }

	object, embed { outline: 0; }

	a { outline: 0; }

	a:hover, a:active, a:focus { outline: 0; }

	.hide { display: none; }

	.container {
		max-width: 1200px;
		width: 92%;
		margin: 0 auto;
		position: relative;

		&:after { clear: both; }
	}

	.clearfix::before, .clearfix::after {
	    content: " ";
	    display: table;
	    clear: both;
	}

	ul, ol { 
		padding: 0; 
		margin: 0;
	}

	.background {
		background-position: center center;
    	background-size: auto;
    	background-repeat: no-repeat;
    	height: 100%;
    	width: 100%;
    }

	.page-content {
		position: relative;
		background: #fff;
		-webkit-transition: right 0.2s ease 0s;
		transition: right 0.2s ease 0s;
		right: 0;
		display: flex;
		min-height: 100vh;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-top: 8.625rem;

		&.nav-is-visible { 
			right: 260px; 

			.header__bottom-bar {
				left: -260px;
				right: 260px;
				
				.nav__right {
				    -webkit-transform: translateX(0px);
					-ms-transform: translateX(0px);
					transform: translateX(0px);
				}
			}
		}

		.titleBar {
			background-color: $color-dark;
			border-top: 1px solid #494949;
		
			.header__title-bar {
				padding: 25px 0;
				text-align: center;

				h1 {
					margin: 0;
		    		font-size: 46px;
		    		line-height: 46px;
		    		text-transform: uppercase;
		    		font-family: "Flama Condensed Light", sans-serif;
		    		font-weight: normal;
		    		color: #ffffff;
				}
			}
		}	

		#captcha_container{
			text-align: center; 
			margin-top: 5px;
			transform: scale(0.93);
			-webkit-transform: scale(0.93);
			-moz-transform: scale(0.93);
			-ms-transform: scale(0.93);
			-o-transform: scale(0.93);

            transform-origin: 0 0 0;
            -webkit-transform-origin: 0 0 0;
            -moz-transform-origin: 0 0 0;
            -ms-transform-origin: 0 0 0;
            -o-transform-origin: 0 0 0;

            label {
            	position: relative;
            	top: 10px; 
            }
		}	
	}

	.icon {
	    display: inline-block;
	    fill: currentcolor;
	    height: 1.5rem;
	    vertical-align: middle;
	    width: 1.5rem;
	}

	.arrow-right {
		width: 0; 
		height: 0; 
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 10px solid #999;
		display: inline-block;
		margin-right: 24px;
		-webkit-transition: all 0.3s; /* Safari */
    	transition: all 0.3s;
	}

	[aria-expanded="true"] .arrow-right {
		-ms-transform: rotate(90deg); /* IE 9 */
	    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
	    transform: rotate(90deg);
	}

	hr {
		border: solid #999;
		border-width: 1px 0 0;
		clear: both;
		margin: 0 0 1.1875rem;
		height: 0
	}

	.modal-content { border-radius: 0; }

	.modal-backdrop.in {
		opacity: 0.85;
	}

	button {
		background-color: #DA291C;
		color: #fff;
		border: 0;
		font-size: 16px;
		line-height: 53px;
		font-family: "Flama Basic", sans-serif;
		font-weight: normal;
		height: 50px;
		text-transform: uppercase;
		padding: 0 20px 1px;
		display: inline-block;
		margin: 0 4px;
		-webkit-transition: all 0.2s ease 0s;
		transition: all 0.2s ease 0s;
		&:hover {
			background-color: #C82127;
		}

		&.gray {
			background-color: #ccc;
			color: #000;
			&:hover {
				background-color: #999;
			}
		}

		&[disabled] {
			background-color: transparent !important;
			border: 1px solid #cccccc !important;
			color: #cccccc !important;
		}

		&:focus {outline:none;}
	}

	a.gray {
		color: #fff;
		border: 0;
		font-size: 16px;
		line-height: 53px;
		font-family: "Flama Basic", sans-serif;
		font-weight: normal;
		height: 50px;
		text-transform: uppercase;
		padding: 0 20px 1px;
		display: inline-block;
		margin: 0 4px;
		background-color: #ccc;
		color: #000;
		&:hover {
			text-decoration: none;
		}
	}

	.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn.active.focus {
	    outline: none;
	    outline-offset: 0;
	}
	.btn:active, .btn.active { box-shadow: none; }


	/*safari for windows fix */ 

	.safari-windows, .ie9 { 

		.page-content {
		  	min-height: 0;
		}

		.header {
  			display: block !important;

  			.logo svg {
			   height: 4.5rem;
			}

			.nav__bottom {
				bottom: 7px !important;
				position: absolute;
				right: 0px;
			}
  		}

  		.tile__content {
		  display: block !important;
		  padding: 16px 20px 14px !important; 

		    .tile__content__inner-left {
			  display: inline !important;
			  vertical-align: middle !important;
			}

			.tile__content__inner-right {
			  display: inline-block !important;
			  line-height: 40px !important;
			  vertical-align: top;
			}
		}

		.page-inner .content-with-sidebar .sidebar{

			short-box-info .details__content {
			  padding-left: 72px;
			}

			.tile__content span {
		  		line-height: 43px;
		    }

			.text-section .image-section {
			  max-width: 500px !important;

			  	img{
				  max-width: 500px !important;
				  width: auto !important;
				}


			}
		}


		.page-order{

			.content-with-sidebar{ 

				.sidebar{

				    .cart .cart__inner__empty img {
					  display: block;
					}


					.gift-cards .tabs__title {
					  display: inline-block;

						p {
						    display: block;
						    float: none;
						}

						img {
						    display: inline-block;
						}
					}
				}
			}

			.step-1 li {
				  display: inline-block;

				  .left__part {
				  	 display: inline-block;
				  }

				  .right__part {
				  	display: inline-block;
				  	float: right;
  					padding-top: 20px;
				  }

			}

			.tabs__content.step-1 {

				.remove {
					height: 55px !important;
    				padding-bottom: 30px !important;
				}	
			}

			.step-4 li .card-overview {
				.remove {
			    	height: 35px !important;

			    	span{
					    height: 35px !important;
					}
				}

				.edit {
			    	height: 35px !important;

			    	span{
					    height: 35px !important;
					}
				}
			}

		}

		.sidebar{
			 .remove {
		    	height: 35px !important;

		    	span{
				    height: 35px !important;
				}
			}

			.edit {
		    	height: 35px !important;

		    	span{
				    height: 35px !important;
				}
			}
		}



		@media (min-width: 1023px) and (max-width: 1280px) {

			.page-order .content-with-sidebar .gift-cards {
			   	max-width: 60% !important;
			}

			.page-account .content-with-sidebar .orders {
			    max-width: 56% !important;
			}
		}



		@media (max-width: 1023px) {

			.header {

				.logo svg {
			     	height: 3.5rem;
			    }
			    .header__top-bar nav {
			    	display: none;
				}
				.nav {
			    	display: inline-block;
			    	float: left;
				}

				.nav-trigger  {
				    display: inline-block;
				    float: right;
				    margin-top: 20px;
			  	}

			}


		
			footer {
			    clear: both !important;
			}

		}

 	}

 	.ie9 {

 		#captcha_container {
 			margin-left: -10px !important;
 		}

 		.tile__content { 

 			.tile__content__inner-left {

 				vertical-align: top !important;


	 			.icon-corporate-wrapp {

	 					vertical-align: baseline !important;

	 				.icon-corporate {
	 					vertical-align: bottom !important;
	 				}
	 			}


	 		}

 			.tile__content__inner-right {

 				vertical-align: baseline !important;
 			}
 		}

 		.page-home {

 			.tile__content { 
 				.tile__content__inner-left {

 					vertical-align: middle !important;
 				}
 			}

 		}

 		.form-section{
 			 .form-group {

 			 	.btn.checkbox {
 			 		span.text {
 			 			white-space: pre-wrap !important;
 			 			word-wrap: break-word !important;
 			 		}
 			 	} 
 			}

 		}

 		.page-register .form-section form .form-group .btn.checkbox span.text {
		    white-space: initial !important;
		}
 	}

 	.safari-windows {

	 	.page-order {
	 	 	.step-3 {
	 	 		 .submit {
	 	 		 	 .btn-group {
						width: 60%;

						@include xs-only {
							width: 100%;
						}
					}

				}
			}
		}

	}


	@include xs-and-sm {

		.page-content {
			padding-top: 7.4rem;
		}
	}

	@include xs-only {
		
		.page-content .titleBar .header__title-bar h1 { font-size: 35px; }		

		.page-content {
			display: block;
			min-height: 0;
		}
	}


/****** header section ******/
@import "header";

/****** footer section ******/
@import "footer";	

/****** form section ******/
@import "form";

/****** landing page ******/
@import "pages/page-home";

/****** register page ******/
@import "pages/page-register";

/****** login page ******/
@import "pages/page-login";

/****** account page ******/
@import "pages/page-account";

/****** inner pages ******/
@import "pages/page-inner";

/****** about page ******/
@import "pages/page-contact";

/****** how to page ******/
@import "pages/page-how-to";

/****** corporate page ******/
@import "pages/page-corporate";

/****** how to page ******/
@import "pages/page-cardholder-login";

/****** balance page ******/
@import "pages/page-balance";

/****** order page ******/
@import "pages/page-order";

/****** order page ******/
@import "pages/page-order-step-1";

/****** order page step 2 ******/
@import "pages/page-order-step-2";

/****** order page step 3 ******/
@import "pages/page-order-step-3";

/****** order page step 4 ******/
@import "pages/page-order-step-4";

/****** order page step 5 ******/
@import "pages/page-order-step-5";

/****** order page step 6 ******/
@import "pages/page-order-step-6";

/****** order page step 7 ******/
@import "pages/page-order-step-7";

/****** order 404 ******/
@import "pages/page-404";




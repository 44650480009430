@import "toolkit";

.page-cardholder-login {
	flex-grow: 1;

	.small-container { 

		max-width: 600px; 
	}

	@include xs-and-sm {
		margin-bottom: 70px;
	}	

	@include xs-only {
		.form-section{
			margin-top: 36px;
		}
	}
}
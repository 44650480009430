@import "toolkit";

.page-order .step-3 {

	.form-group.radio {
		.btn-group { margin-top: 10px; }
		.btn {
			padding: 10px 15px; 
			margin: 0;
			input { width: 0; }
		}
	}

	.inner-wrapper { 
		width: 366px; 
		max-width: 100%;
		margin: 30px 0 0;
	}

	.submit { 
		display: block; 
		border-width: 1px;
		.btn-group { 
			width: -moz-calc(100% - 240px);
			width: -webkit-calc(100% - 240px);
			width: calc(100% - 240px);
			.btn.checkbox {
				margin-top: 0 !important;
				span.icon { margin-top: 2px; }
				span.text {
					font-size: 15px;
					line-height: 20px;
				}
			}
		}
	}

	@include xs-only {

		.submit .btn-group { 
			width: 100%;
		}

		.form-group.radio .btn {
			width: 100%;
			margin: 10px 0;
			max-width: 338px;
		}
	
	}
}
@import "toolkit";

.page-order {
	flex-grow: 1;

		.step-4 {
			width: 100%;

			h2 { margin-bottom: 22px !important;}

			ul { list-style: none; }
				
			li { 
				border: 1px solid #999; 
				line-height: 28px;
				background-color: #fff;
				text-align: left;
				font-family: Arial;
				font-size: 15px;
				color: $color-dark;
				width: 100%;
				display: block;
				margin-bottom: 18px !important;
				padding: 15px 0 10px;

				.card-overview {

					padding: 0 20px 15px 30px;

					.top-part {

						h2 { float: left; }
						.buttons-right { 
							float: right; 
							input[type="submit"] {
								display: none;
							}
						}
					}

					.images-wrapper {
						float: left;
						max-width: -moz-calc(100% - 288px);
						max-width: -webkit-calc(100% - 288px);
						max-width: calc(100% - 288px);
						width: 330px;

						img { 
							width: -moz-calc(48.9% - 10px);
							width: -webkit-calc(48.9% - 10px);
							width: calc(48.9% - 10px);
							height: auto;

							&.custom {													
								border-radius: 8px;
								border: 1px solid #999;
							}

							&:first-child {
								margin-right: 10px;			
							}
						}
					}

					.remove, .edit {
						background-color: transparent;
					    height: 33px;
					    line-height: 23px;
					    margin: 0;
					    padding: 0 12px 0 0;
					
						span {
							color: #c82128 !important;
						    font-size: 38px;
						    margin-top: 0;
						    opacity: 1;
						}
					}

					.card-info {
						float: right;
						width: 280px;

						label { line-height: 25px; }

						span { 
							float: right; 

							&.message {
								float: none;
								display: block;
								word-wrap: break-word;
							}
						}
					}
				}	

				span { word-wrap: break-word; }

				.card-value {
					background-color: #fff;
				    border-top: 1px solid #999;
				    color: #000;
				    font-family: "Flama";
				    font-size: 25px;
				    line-height: 5px;
				    padding: 20px 24px 14px;
				    text-align: right;
				    text-transform: uppercase;
				}

				.delivery {

					.shipping-info {
						float: left;
					}
				}

			}

			.total-value {
				padding: 0px 0 10px;
				font-family: "Flama Light", sans-serif;
				text-align: right;
				font-size: 25px;
				line-height: 25px;
				text-transform: uppercase;
				color: #000;							
				background-color: #fff;

				label {
					display: block;
					margin-bottom: 4px;
					font-weight: 400;
				}

				a { 
					background-color: #da291c;
				    border: 0 none;
				    color: #fff;
				    font-family: "Flama",sans-serif;
				    font-size: 16px;
				    font-weight: normal;
				    height: 50px;
				    line-height: 50px;
				    padding-bottom: 1px;
				    text-transform: uppercase;
				    width: 134px;
				    text-decoration: none;
				    text-align: center;
				    display: inline-block;
				}
			}

			.pay {
				float: right;
				margin-right: 0;
				margin-bottom: 10px;
				background-color: $color-red;
				color: #fff;
				border: 0;
				font-size: 16px;
				line-height: 53px;
				font-family: "Flama Basic", sans-serif;
				font-weight: normal;
				height: 50px;
				text-transform: uppercase;
				padding: 0 20px 1px;
				display: inline-block;
				-webkit-transition: all 0.2s ease 0s;
				transition: all 0.2s ease 0s;
				&:hover {
					background-color: #C82127;
					text-decoration: none;
				}
			}


			.bottom-text {
				text-align: right;
				font-size: 15px;
				font-family: "Arial", sans-serif;

				a {
					text-decoration: none;
					color: $color-red;
				}

			}

			@include xs-only {

			li {
				display: block;

				.card-overview {
					padding-left: 20px;

					.images-wrapper {
						float: none !important;
						max-width: 100% !important;
						margin-bottom: 10px !important;
						margin-top: 10px !important;
					}

					.card-info {
						float: none !important;
	    				width: 100% !important;
					}
				}
			}
			.gray {
				float: right !important;
				clear: both !important;
				margin: 0 0 10px !important;
				padding: 0 13px 1px !important;
				margin: 0 0 10px !important;
			    text-align: center;
			    width: 170px;
			}
		}
		
	}
}
@import "toolkit";

.page-order .step-2 {

	#accordion { margin-bottom: 0; }

	.panel-group .panel {
		border-radius: 0;

		&.filled .panel-title a .icon { display: block; }

		&+ .panel {
			margin-top: 0;
			border-top: 0;
		}
	}

	.panel {
		box-shadow: none;
		border-color: $color-border;
	}

	.panel-heading { padding: 0; }

	.panel-body { 
		padding: 0;
		border-color: $color-border !important;

		h2 { margin-bottom: 18px; }
	}

	.panel-title a { 
		padding: 10px 24px; 
		display: block;
		text-decoration: none; 
		line-height: 43px; 
		text-transform: uppercase;
		font-family: "Flama Light";
		font-size: 25px;
		color: #000;
		position: relative;
		.icon {
		    background: #fff none repeat scroll 0 0;
		    border-radius: 50%;
		    top: 15px;
		    color: #c82128;
		    font-size: 42px;
		    height: 32px;
		    line-height: 34px;
		    margin: 0;
		    padding: 0;
		    position: absolute;
		    right: 20px;
		    width: 32px;			    
		    display: none;
		    &:before {
		    	position: relative;
			    left: -5px;
			    top: -3px;
		    }
		}
	}

	h2 { margin: 0; }

	.card-design {			
		padding: 25px 20px 40px;

		h2 .price {
			float: right;
			font-size: 30px;
		}

		.btn { 
			width: -moz-calc(33.33% - 20px);
			width: -webkit-calc(33.33% - 20px);
			width: calc(33.33% - 20px);
			min-width: 228px;
			height: 223px;
			
			&.active[data-toggle="modal"] {
				border: 0;
				box-shadow: none;
				background: transparent;
				&.filled {
					border: 1px solid #c82128; 
					box-shadow: 0px 0px 2px 2px rgba(136, 136, 136, 0.4);
					background-color: #f5f5f5;
					cursor: default;
					.edit, .remove { display: block; }
				}
			}	

			&.filled .final-img { 
				display: block; 
				&[src=""] { display: none; }
			}

			.final-img { 
				display: none; 
				border-radius: 9px;
			    left: 50%;
				margin-left: -101.5px;
			    position: absolute;
			    top: 13px;
			    width: 203px;
			    &.portrait {
			    	left: 50%;
				    margin-left: -64.5px;
				    margin-top: -24px;
				    max-height: 203px;
				    position: absolute;
				    top: 0;
				    -ms-transform: rotate(-90deg); /* IE 9 */
				    -webkit-transform: rotate(-90deg); /* Safari */
				    transform: rotate(-90deg);
				    width: auto;
			    }
			}

		}
	}

	.greeting-card {	
		padding: 30px 20px 35px;
		border-top: 1px solid $color-border;

		h2 { 
			margin-bottom: 30px; 
			.price { float: right; font-size: 30px; }
		}

		.btn { 
			width: -moz-calc(33.33% - 20px);
			width: -webkit-calc(33.33% - 20px);
			width: calc(33.33% - 20px);
			height: 223px;
		}
	}

	.message {
		padding: 30px 20px 40px;
		border-top: 1px solid $color-border;
		position: relative;

		h2 { text-transform: none; }

		.form-group {
			width: -moz-calc(100% - 300px);
			width: -webkit-calc(100% - 300px);
			width: calc(100% - 300px);
			max-width: 338px;
		}

		input, .btn-group.bootstrap-select { width: 100%; max-width: 338px; }

		textarea { 
			min-height: 152px; 
			width: 100%;
			max-width: 338px;
			min-width: 100%;
			display: block;
			margin-top: 13px;
		}

		img {
			position: absolute;
			right: 34px;
			top: 33px;
		}
	}

	.done {
		padding: 10px 20px;
		border-top: 1px solid $color-border;

		.form-group { margin-bottom: 0; }

		.btn-group { 
			float: left; 
			.btn.checkbox .text {
				font-size: 15px;
				padding-bottom: 10px;
			}
		}

		.btn { margin-top: 8px !important; }

		button { float: right; margin: 0; }
	}


	#goToStep2 { display: none; }

	#uploadImageTerms {
		text-align: center;

		.modal-dialog { 
			margin-top: 215px; 
			width: 436px; 
			max-width: -moz-calc(100% - 20px);
			max-width: -webkit-calc(100% - 20px);
			max-width: calc(100% - 20px);
		}
		.modal-body {
			font-family: Arial;
			font-size: 14px;
			line-height: 22px;
			padding: 35px 15px 5px;

			a { color: $color-red; }				
		}
		.modal-footer { 
			text-align: center; 
			border-top: 0;
			padding-bottom: 27px;
		}
		button { display: inline-block; }
	}

	#uploadImageStep1 {

		.modal-dialog { 
			margin-top: 140px; 
			width: 800px; 
			max-width: -moz-calc(100% - 20px);
			max-width: -webkit-calc(100% - 20px);
			max-width: calc(100% - 20px);
		}
		.modal-body { padding: 30px 25px 30px 30px; }
		.modal-footer { background-color: #eee; border-top: 1px solid #999; }
		h2 {
			text-transform: uppercase;
			font-family: "Flama Light";
			font-size: 25px;
			color: #000;
		}
		.left-col { 
			float: left; 
			max-width: -moz-calc(100% - 260px);
			max-width: -webkit-calc(100% - 260px);
			max-width: calc(100% - 260px);
			width: 480px;
		}
		.left-col ul, .left-col p {
			font-family: Arial;
			font-size: 13px;
			line-height: 20px;
			width: 100%;
		}
		ul { list-style: none; padding-top: 5px; }
		li:before {
		    content: "• ";
		    color: $color-red;
		}
		.right-col { 
			float: right; 
			width: 228px;
			color: #4a4a4a;
			margin-top: 15px;

			&.error {
				color: #c82128;
				.msg { display: block; }
				p { color: #c82128; }
			}

			.msg { 
				display: none; 
				font-family: Arial;
				font-size: 12px;
				line-height: 28px;
				font-weight: normal;
				margin-bottom: 0;
				padding-left: 10px;
				color: #fff;
				background-color: #c82128;
				span {
				    font-family: Georgia;
				    font-size: 14px;
				    font-weight: bold;
				    padding-right: 10px;
				}
			}
			p {
				font-family: Arial;
				font-size: 12px;
				line-height: 20px;
				color: #4a4a4a;
				margin-top: 10px;
			}
		}
	}

	#uploadImageStep2 {
		overflow: scroll;
		&.portrait {
			.card-wrapper {
				width: 218px;
				height: 345px;
				margin-right: 12px;
				.card-frame { display: none; }
				.card-frame-portrait { display: block; }
			}
			.left button { 
				&.landscape { color: $color-dark; }
				&.portrait { color: #c82128; }
			}
		}

		&.loading {
			.loading-msg { display: block; }
			.img-wrapper {  opacity: 0; }
		}

		.img-wrapper {
			transform-origin: 0 0 0;
    		width: 734px;
		}

		.modal-dialog { 
			width: 800px; 
			max-width: -moz-calc(100% - 20px);
			max-width: -webkit-calc(100% - 20px);
			max-width: calc(100% - 20px);
			overflow: hidden;
		}
		.modal-content { border-radius: 0; }
		.modal-body { padding: 46px 32px 55px; }
		.modal-footer { background-color: #eee; border-top: 1px solid #999; }
		
		.loading-msg { display: none; }

		#original-wrapper {
			width: 345px;
			/*height: 218px;*/
			float: left;
		}

		.card-wrapper {
			width: 345px;
			height: 218px;
			overflow: hidden;
			position: relative;
			float: right;				
		}

		#original-img-hidden { opacity: 0; }

		#cropped-img {
			width: 100%;
			height: auto;
			position: absolute;
			top: 0;
			left: 0;
		}

		.card-frame, .card-frame-portrait { 
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.card-frame-portrait { display: none; }

		.left { 
			float: left; 
			button {
				background: transparent none repeat scroll 0 0;
			    color: #29292b;
			    font-family: Arial;
			    font-size: 13px;
			    height: 70px;
			    line-height: 13px;
			    padding-top: 57px;
			    position: relative;
			    text-transform: none;
			    &.landscape {
			    	color: #c82128;
			    	span { top: 4px; }
			    }
			    &.portrait span { top: -2px; left: 11px; }
			    &.change-image span { top: 3px; left: 28px; font-size: 60px; }
			    span {
			    	font-size: 62px;
			    	display: block;
				    margin: 0 auto;
				    position: absolute;
			    }
			}
		}

		h2 { margin-bottom: 25px; }
	}

	@include xs-and-sm {

		#uploadImageStep2 {
			overflow: scroll;
		}

	}
	
	@include xs-only {

		.panel-title a {
			font-size: 22px;
			.arrow-right {
				margin-right: 14px;
			}
		}

		#uploadImageStep1 {
			overflow: scroll;
			.modal-dialog {
				margin: 10px;
			}
			.left-col, .left-col p {
				width: 100%;
				float: none;
				max-width: 100%;
			}
			.right-col {
				float: none;
				margin: 15px auto 0;
			}
		}

		#uploadImageStep2 {
			&.portrait .card-wrapper {
				margin: 10px auto 0;
			}
			.img-wrapper { width: 100%; }
			#original-wrapper {
				float: none;
				margin: 0 auto;
			}
			.card-wrapper {
				float: none;
				margin: 10px auto 0;
			}
			.left {
				float: none;
    			text-align: center;
			}
			.right {
				margin-top: 10px;
			    padding-top: 15px;
			    text-align: center;
			    border-top: 1px solid #999;
			}
			.modal-body {
				padding-left: 12px;
				padding-right: 12px;
			}
			.modal-footer {
				padding: 15px 0;
				.left button {
					padding-left: 10px;
					padding-right: 10px;
					&.landscape span { left: 12px; }			    
				    &.portrait span { left: 1px; }
				    &.change-image span { left: 21px; }
				}
			}
		}

		.card-design {
			h2 .price {
				float: none;
				display: block;
				margin-top: 10px;
			}
			.btn {
				width: 100%;
				margin: 10px 0 !important;
			}
		}
		
		.greeting-card {
			h2 .price {
				float: none;
				display: block;
				margin-top: 10px;
			}
			.btn {
				width: 100%;
				margin: 10px 0;
			}
		}

		.message {
			.form-group { 
				width: 100%;
			}
			input, .btn-group.bootstrap-select {
				max-width: 100%
			}
			img {
				display: none;
			}
		}
	}



	@media (max-width: 389px) {
		
		#uploadImageStep2.portrait .card-wrapper {
			margin-left: 60px;
		}
	}
}
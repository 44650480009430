.page-order {
	flex-grow: 1;

	 .content-with-sidebar {

		.tabs.one-tab {

			ul { 

				margin-bottom: 45px !important;

				li {
					&.completed::before {
					    font-size: 28px !important;
					    left: 50% !important;
					    margin-left: -137px !important;
					    position: absolute !important;
					    top: 27px !important;
					}
				}		
			}
		}


		.step-7 {
			width: 100%;

			.message-error {
				line-height: 28px;
				background-color: #fff;
				text-align: center;
				font-family: Arial;
				font-size: 15px;
				color: $color-dark;
				width: 100%;
			}

		}

		@include xs-only {

			.tabs.one-tab {

				ul { 

					li {
						&.completed::before {

							font-size: 23px !important;
						    left: 50% !important;
						    margin-left: -111px !important;
						    position: absolute !important;
						    top: 9px !important;
						}
					}
				}
			}
		}
	}
}
.page-404 {
    flex-grow: 1;
    .u-text-align-center {
        text-align: center;
    }
    .u-s-m-huge {
        margin: 2.5rem;
    }
    .u-text-feature {
        font-family: "Flama",sans-serif;
        text-transform: uppercase;
        font-size: 2.1875rem;
        line-height: 140%;
        font-weight: 400;
        margin: 0 0 .625rem;	
        color: #222;	
    }
    .u-s-mt-huge {
        margin-top: 2.5rem;
    }
    .u-font--serif {
        font-family: "Times New Roman",Georgia,serif;
        font-style: italic;
    	font-size: 1.125rem;
    	    margin: 0 0 1.25rem;    
    	    color: #222;
        line-height: 140%;	
        font-weight: 400;	
    }
}
@import "toolkit";

.page-order {
	flex-grow: 1;

	.form-section { 
		text-align: left; 
		margin: 0;

		form {
			width: auto;
			margin: 0;
		}
	}

	.popup-yes-no {
		text-align: center;

		.modal-dialog { 
			margin-top: 215px; 
			width: 436px; 
			max-width: -moz-calc(100% - 20px);
			max-width: -webkit-calc(100% - 20px);
			max-width: calc(100% - 20px);
		}
		.modal-body {
			font-family: Arial;
			font-size: 14px;
			line-height: 22px;
			padding: 35px 15px 5px;

			a { color: $color-red; }				
		}
		.modal-footer { 
			text-align: center; 
			border-top: 0;
			padding-bottom: 27px;
		}
		button { display: inline-block; }

		a {
			background-color: $color-red;
			color: #fff;
			border: 0;
			font-size: 16px;
			line-height: 53px;
			font-family: "Flama Basic", sans-serif;
			font-weight: normal;
			height: 50px;
			text-transform: uppercase;
			padding: 0 20px 1px;
			display: inline-block;
			margin: 0 4px;
			-webkit-transition: all 0.2s ease 0s;
			transition: all 0.2s ease 0s;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.submit {
		padding: 10px 20px;
		border-style: solid;
		border-color: $color-border;
		border-width: 0 1px 1px;
		display: none;
		&.active { display: block; }
	}

	.content-with-sidebar {
		padding-top: 80px;
		position: relative;

		.gift-cards {
			display: inline-block;
			width: 786px;
			max-width: -moz-calc(100% - 408px);
			max-width: -webkit-calc(100% - 408px);
			max-width: calc(100% - 408px);
			margin: 0 0 0;

			h2 {
				font-family: "Flama Light", sans-serif;
				font-weight: normal;
				font-size: 25px;
				line-height: 25px;
				color: #000;
				text-transform: uppercase;
				margin: 0 0;
			}

			.tabs {

				position: relative;
				z-index: 1;

				ul {
					list-style: none;
					display: table;
					margin-bottom: 36px;
					width: 100%;

					li {
						list-style: none;
						display: table-cell;
						line-height: 78px;
						background-color: #29292b;
						margin: 0;
						color: rgba(255,255,255,0.3);
						text-align: center;
						padding-left: 22px;
						text-transform: uppercase;
						font-family: "Flama Condensed Light";
						font-size: 24px;
  						position: relative;
  						
						&.active, &.completed {
							background-color: #c82128;
							color: #ffffff;

							&:after {
								background-image: url('../img/inner/arrow-red.png');
							}

							a { color: #fff; }
						}

						&.completed {

							&:before {
								font-size: 20px;
							    left: 28px;
							    position: absolute;
							    top: 31px;
							}
						}


						&:first-child {
							&.completed {
								&:before {
									left: 18px;
								}
							}
						} 

						&:after {
						  	left: 100%;
						    background-repeat: no-repeat;
						    content: " ";
						    height: 82px;
						    position: absolute;
						    width: 52px;
						    z-index: 1000000;
							background-image: url('../img/inner/arrow-black.png');
						}

						&:last-child:after { display: none; }

						a { text-decoration: none; color: rgba(255,255,255,0.3); }

						label {
							display: none;
						}
					}
				}
			}

			.tabs__title {
				display: inline-block !important;

				p {
					display: block !important;
					float: none;
					margin-top: 5px;
					margin-bottom: 25px;
					line-height: 25px;
				}
				img {
					display: inline-block !important;
					float: right;
					width: 119px;
					height: 85px;
					margin-top: -28px 
				}
			}

			table {
				width: 100%;

				tbody {
					
					tr { 
						border: 1px solid #999; 

						&.total td {
							padding: 14px 24px;
							font-family: "Flama",sans-serif;
							text-align: right;
							font-size: 20px;
							line-height: 35px;
							text-transform: uppercase;
							color: #000;							
							background-color: #fff;

							label {
								display: block;
								margin-bottom: 4px;
							}

							a { 
								background-color: #da291c;
							    border: 0 none;
							    color: #fff;
							    font-family: "Flama",sans-serif;
							    font-size: 16px;
							    font-weight: normal;
							    height: 50px;
							    line-height: 50px;
							    padding-bottom: 1px;
							    text-transform: uppercase;
							    width: 134px;
							    text-decoration: none;
							    text-align: center;
							    display: inline-block;
							}
						}

						&.add_new td {
							text-align: left;
							font-family: "Flama Light", sans-serif;
							text-transform: uppercase;
							font-size: 17px;
							line-height: 25px;
							color: #29292b;
							background-color: #fff;

							img {
								vertical-align: middle;
								margin-right: 6px;
							} 

							label {
								vertical-align: middle;
							}
						}
					}

					td {
						line-height: 28px;
						background-color: #ddd;
						padding: 10px 0;
						text-align: left;
						font-family: Arial;
						font-size: 15px;
						color: $color-dark;
						&:first-child { padding-left: 18px; }
						&:last-child { 
							padding-right: 22px; 
							text-align: right;
							font-size: 30px;
							font-family: "Flama",sans-serif;

							span {
								font-size: 25px;
								padding-right: 10px;
							}
						}

						input {
							background-color: #ffffff;
						    border: 1px solid #999999;
						    border-radius: 0;
						    box-shadow: none;
						    color: #29292b;
						    font-family: Arial,sans-serif;
						    font-size: 14px;
						    font-weight: normal;
						    height: 40px;
						    width: 100px;
						    line-height: 20px;
						    padding: 10px 12px !important;
						}

						p {
							margin: 0 0 0 -2px;
							font-family: "Arial", sans-serif;
							font-size: 11px;	
							color: #29292b;
							opacity: 0.8
						}

						&.dropdown-cell {
							width: 106px;
						}

						&.operation {
							width: 30px;
							text-align: center;
							font-size: 15px;
							font-family: "Flama",sans-serif;
							padding-top: 0px;
						}
					}
				}
			}

			.bottom-text {
				text-align: right;
				font-size: 15px;
				font-family: "Arial", sans-serif;

				button {
					text-decoration: none;
					color: $color-red;
				}
				.gray { 
					color: $color-dark !important; 
					float: left;
					margin: 12px 10px 0 0; 		
					-webkit-transition: all 0.2s ease 0s;
					transition: all 0.2s ease 0s;			
					&:hover { background-color: #999; }
				}

				p { margin-bottom: 0; }
			}
		}

		.cart__mobile {
			text-align: right;
			display: none;
			align-items: center;
			margin-bottom: 15px;

			h1 {
				font-family: "Flama Condensed Light", sans-serif;
				font-size: 25px;
				flex: 1 1 0;
				color: #999;
				font-weight: normal;
				text-transform: uppercase;
				display: inline-block;
				margin-right: 15px;
			}

			img {
				width: 48px;
				height: 40px;
				display: inline-block;
			}
		}

		.sidebar {
			float: right;
			width: 372px;
			max-width: 100%;
			font-family: Arial;
			font-size: 14px;
			margin-bottom: 30px;

			.background {
				height: 212px;
			    position: relative;
			    width: 100%;
			}	

			.short-box { 
	    		text-decoration: none;
	    		width: 100%;

	    		&.popup__card{
	    			display: none;
	    		}
			}

			.tile__content { 
	    		background-color: #29292b;
	    		height: 78px;
	    		padding: 13px 30px;
	    		color: #fff;
	    		font-size: 25px;

	    		.tile__content__inner-center {
		    			font-size: 35px;
		    			line-height: 50px;
		    			text-transform: uppercase;
		    			font-family: "Flama Condensed Light", sans-serif;
		    			text-align: center;
		    	}

	    		.number__of__products{
	    			float: right;
	    			margin-top: 8px;
	    			position: relative;
	    			cursor: pointer;

	    			label {
	    				background-color: #C82128;
	    				border-radius: 50%;
	    				position: absolute;
	    				top: -5px;
	    				left: -15px;
	    				font-size: 15px !important;
	    				color: #fff;
	    				font-family: "Flama",sans-serif !important;
	    				font-weight: normal !important;
	    				width: 32px;
	    				height: 32px;
	    				text-align: center;
	    				line-height: 32px;
	    			}
	    		}

	    		.close__popup__cart__wrapp {
	    			float: right;
	    			margin-top: 0px;
	    			position: relative;
	    			cursor: pointer;

	    			.close__popup__cart {

					    background-color: #fff;
					    height: 26px;
					    margin: 0;
					    padding: 0;
					    position: relative;
					    width: 27px;

						span {
							color: #c82128 !important;
						    font-size: 38px;
						    left: -4px;
						    margin-top: 0;
						    opacity: 1;
						    position: absolute;
						    top: -3px;
						    &:before {
						    	position: relative;
						    	top: -3px;
						    }
						}
	    			}
	    		}


	    	}

	    	.full__cart {

				.tile__content{
				
					padding: 13px 22px;
				}
    		
	    		.tile__content__inner-center{

    				text-align: left !important;
    				float: left;
	    		}

	    		.cart {
	    			padding-top: 0;
	    			padding-bottom: 12px;
	    		}

		   }

	    	.cart {
	    		border: 1px solid #999;
	    		border-top: 0px;
			   display: table;
			   height: auto;
			   padding: 70px 0 0;
			   width: 372px;

	    		.cart__inner__empty{

	    			img {

	    				display: flex;
				   	margin: 0 auto;
				    	width: 72px;
				   }

				    h1 {
					   color: #999;
					   font-family: "Flama Condensed Light",sans-serif;
					   font-size: 35px;
					   font-weight: normal;
					   line-height: 35px;
					   text-align: center;
					   text-transform: uppercase;
					   vertical-align: middle;
		    		}
	    		}

	    		.cart__inner_full {

	    			ul {
		    			list-style: none;
					}

					li {
						border-bottom: 1px solid #999; 
						line-height: 28px;
						background-color: #fff;
						text-align: left;
						font-family: Arial;
						font-size: 15px;
						color: $color-dark;
						width: 100%;
						display: inline-block;
						padding: 15px 20px 15px 15px;


						.left__part {
							display: inline-block;
							font-family: "Flama Light" !important;
							font-size: 20px;

							img { 
								margin-right: 5px;
							}

							input[type="submit"] {
								display: none;
							}

							.edit-image {

								float: left;
								margin-right: 10px;

								.edit {
									background-color: transparent;
								    height: 33px;
								    line-height: 23px;
								    margin: 0 0 0 -4px;
								    padding: 0 15px 0 0;

								    span {
										color: #c82128 !important;
									    font-size: 38px;
									    margin-top: 0;
									    opacity: 1;
									}
								}	
							}

							.remove {
								background-color: transparent;
							    height: 33px;
							    line-height: 23px;
							    margin: 0 0 0 -4px;
							    padding: 0 19px 0 0;
							
								span {
									color: #c82128 !important;
								    font-size: 38px;
								    margin-top: 0;
								    opacity: 1;
								}
							}	

							.thumb-image {
								border: 1px solid #999;
								width: 104px;
								height: 76px;
								text-align: center;
								float: left;
								margin-right: 10px;
								margin-top: 5px; 
								padding-top:10px;

								&.design .wrapp-thumb {									
									&.portrait, &.landscape {
										border: 1px solid #999;
										border-radius: 4px;
										overflow: hidden;
										max-width: 60px;
									    max-height: 40px;
									    img { width: 60px; }
									}
									&.portrait {
								    	max-height: 60px;
								    	max-width: 40px;
								    	margin: -12px auto -14px;
									    transform: rotate(-90deg);

								    	img { width: 40px; height: 60px; }
								    }
								    img { width: 60px; }
								}

								.wrapp-thumb {
									margin: 0 auto;
								    max-height: 42px;
								    max-width: 72px;
								    

									img {
										vertical-align: middle;
										margin: 0 auto;
										width: 70px;
										height: auto;
									}

									&.no-card {
										img { width: 41px; }
									}

								}
							}

							span, label {
								font-family: "Flama Light" !important;
								font-size: 20px;
								font-weight: normal !important;
								text-transform: uppercase;
								vertical-align: middle;
								margin-bottom: 0px;
							}

							.operation { 
								text-transform: lowercase !important;
								vertical-align: middle;
								padding: 0 5px;
							}

							.dolar__bag {
								padding-right: 5px;
							}
						}

						.custom__card__row {
							margin-top: 5px;

							.left__part {
								display: block;

								label {
									display: block;
									text-align: center;
									font-size: 10px;
									color: #29292b;
									font-family: "Arial", sans-serif;
									font-weight: normal;
									text-transform: capitalize;
									line-height: 10px;
									padding-top: 8px;
								}
							}


						}

						.right__part {
							display: inline-block;
							float: right;

							.price__bag {
								font-family: "Flama",sans-serif !important;
								font-size: 20px !important;
								vertical-align: middle;
							}

							span {
								vertical-align: middle;
							}
						}
  					}

  					.total{

  						padding: 17px 20px 0px 15px;

  						button.gray {
						    margin: 4px 0 0;
						}

	  					.left-total {
	  						float: left;
	  						display: inline;

	  						label {
	  							font-family: "Flama Light" !important;
								font-size: 20px;
	  						}
	  					}

	  					.right-total {
	  						float: right;
	  						display: inline;

	  						span {
	  							font-family: "Flama Light" !important;
								font-size: 20px !important;
								font-weight: 700;
	  						}
	  					}
	  				}
	    		}

	    	}

	    	.note {
	    		font-size: 12px;
	    		font-family: "Arial", sans-serif;
	    		line-height: 17px;
	    	}
		}

	}

	// smaller-desktop
	@media (min-width: 1120px ) and (max-width: 1280px) {
	   	.content-with-sidebar {			
	   		.tabs {
	   			li {
	   				font-size: 23px !important;
	   				padding-left: 26px;

   					&.completed {

						&:before {
							font-size: 14px !important;
						    left: 30px !important;
						    top: 32px !important;
						}

						a {
							margin-left: 10px;
						}
					}


					&:first-child {
						&.completed {
							&:before {
								left: 22px !important;
							}
						}
					} 
	   			}
	   		}
	   	}
	}
	// smaller-desktop-1120
	@media (min-width: 1024px ) and (max-width: 1119px) {
	   	.content-with-sidebar {			
	   		.tabs {
	   			li {
	   				font-size: 21px !important;
	   				padding-left: 26px;

   					&.completed {

						&:before {
							font-size: 13px !important;
						    left: 25px !important;
						    top: 34px !important;
						}
					}


					&:first-child {
						&.completed {
							&:before {
								left: 15px !important;
							}
						}
					} 
	   			}
	   		}
	   	}
	}

	@include xs-and-sm {

		.modal-dialog {
			margin: 10px auto;
		}
		
		.content-with-sidebar {

			padding-top: 22px;
			position: relative;

			.gift-cards {
				float: left;
				width: 100%;
				max-width: 100%;
				margin: 15px 0 0px;

				.tabs {

					li {
						&.completed:before { left: 36px !important; }

	 					&:first-child:before { left: 18px !important; }
					}
				}

				.tabs__title img { display: none !important; }			
			}

			.cart__mobile { 

				margin-bottom: 0px; 

				&.full__cart {

					.number__of__products {
						position: relative;
						margin-left: 10px;
						cursor: pointer;
					}

					label {
						 background-color: #c82128;
					    border-radius: 50%;
					    color: #fff;
					    font-family: "Flama",sans-serif !important;
					    font-size: 15px !important;
					    font-weight: normal !important;
					    height: 32px;
					    left: -15px;
					    line-height: 32px;
					    position: absolute;
					    text-align: center;
					    top: -5px;
					    width: 32px;
					    cursor: pointer;
					}
				}	
					
			}

			.sidebar { 
				clear: left;
				width: 100%;

				.short-box { 


					display: none; 

					&.popup__card {
						display: none;
						left: 0;
					   position: absolute;
					   top: 0;
					   height: 70px;

					   .tile__content__inner-center {

						   font-family: "Flama Condensed Light";
						   font-size: 25px;
						   line-height: 47px;

					   }
					}

					&.open__title{
						display: block !important;
					}
				}

				.full__cart .cart {
    				padding-bottom: 0;
    			}

				.cart { display: none; width: 100% !important; height: auto;}

				.total {
					background-color: #fff;
					border-bottom: 1px solid #999;
					padding-top: 12px !important;				
					padding-bottom: 12px !important;				
				}
			 }
		}

		.cart__mobile { display: flex !important; }

		.open__card {
			display: block !important;
			position: absolute;
			top: 70px;
			left: 0;
			z-index: 100;
			width: 100%;
			border-bottom: 0px !important;

		}
	}



	// smaller-tablet-880
	@media (min-width: 767px ) and (max-width: 880px) {
		
		.content-with-sidebar {
			.gift-cards {
				.tabs {

					li {	

						font-size: 24px !important;

						&.completed:before { 
							left: 30px !important; 
							font-size: 20px !important; 
						}

	 					&:first-child:before { left: 16px !important; }
					}
				}

			}		
		}
	}

	// smaller-tablet-767
	@media (min-width: 640px ) and (max-width: 767px) {
		
		.content-with-sidebar {
			.gift-cards {
				.tabs {

					li {	

						font-size: 21px !important;

						&.completed:before { 
							left: 26px !important; 
							font-size: 18px !important; 
							top: 30px !important;
						}

	 					&:first-child:before { left: 18px !important; }
					}
				}

			}		
		}
	}




	@include xs-only {

		.content-with-sidebar {

			.gift-cards {
				margin: 15px 0 0;
				padding: 0;
				border: 0;

				.tabs {

					ul { margin-bottom: 20px; }

					li {
						padding-left: 0 !important;
						border-right: 1px solid #ddd; 
						font-size: 24px !important;
    					line-height: 40px !important;
    					width: 20%;

						&:after { display: none; }

						&.completed:before{ display: none; } 

						span { display: none; }

						a {
							display: block;
							margin-left: 0;
							label { cursor: pointer; }
						}

						label
						{ 
							display: block !important;
							margin-bottom: 0px !important;							
						}

						&:last-child { border-right: 0px; }

					}
				}

				.tabs__content {

					.tabs__title p { margin-bottom: 20px !important; }

					.bottom-text {

						.gray { 
							float: right;
							margin: 12px 0 0 10px !important; 							
						}

						p {
							clear: both;
							float: right;
						}
					}

				}
			}

			.sidebar 
			{
				
				.shopping__cart.full__cart {

					.cart__inner_full {

						li {
							position: relative;
							padding-right: 0;

							.edit-image {
								margin-right: 6px;
							}

							.thumb-image {
								 margin-top: 35px;
							}

							.right__part {
								display: inline-block;
							   float: none;
							   left: 55px;
							   position: absolute;
							   top: 50px;
							}
						}
					}

				}

			}	
		}
	}
}
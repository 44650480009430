@import "toolkit";

.page-balance {
	flex-grow: 1;

	.balance {
		display: block;
		width: 525px;
		text-align: center;
		margin: 0 auto;
		margin-top: 52px;

		p {
			font-family: "Flama Light", sans-serif;
			font-weight: normal;
			font-size: 15px;
			line-height: 25px;
			color: #000;
			text-transform: uppercase;
			margin-top: 0;
			margin-bottom: 0;
		}

		h1 {
			font-family: "Flama Light", sans-serif;
			font-weight: normal;
			font-size: 50px;
			line-height: 50px;
			color: #000;
			text-transform: uppercase;
			margin: 0 0 40px;
		}


		h2 {
			font-family: "Flama Light", sans-serif;
			font-weight: normal;
			font-size: 32px;
			line-height: 32px;
			color: #000;
			text-transform: uppercase;
			margin: 0 0 60px;
		}

		table {
			width: 100%;
			margin-top: 5px;

			thead {
				background-color: $color-dark;
				color: #fff;
				font-family: "Flama Condensed Light";
				text-transform: uppercase;
				font-size: 25px;
				line-height: 56px;

				tr { border: 1px solid $color-dark; }

				th { 
					font-weight: 400; 
					text-align: left;

					&:first-child { padding-left: 22px; }

					&:last-child { 
						padding-right: 22px; 
						text-align: right;
					}
				}
			}

			tbody {
				
				tr { 
					border: 1px solid #999; 

					&.no-order td {
						padding: 30px;
						font-family: "Flama Condensed Light";
						text-align: center;
						font-size: 25px;
						line-height: 35px;
						text-transform: uppercase;
						color: #999;

						a { 
							color: $color-red; 
							text-decoration: none;
							&:hover { text-decoration: underline; }
						}
					}
				}

				td {
					line-height: 28px;
					padding: 10px 0;
					text-align: left;
					font-family: Arial;
					font-size: 15px;
					color: $color-dark;
					&:first-child { padding-left: 22px; }
					&:last-child { 
						padding-right: 22px; 
						text-align: right;
					}
				}
			}
		}

		a.log__out {
			background-color: #ccc;
		    border: 0 none;
		    color: #000;
		    display: inline-block;
		    font-size: 16px;
		    height: 50px;
		    line-height: 50px;
		    margin-top: 32px;
		    padding-bottom: 1px;
		    text-align: center;
		    text-decoration: none;
		    text-transform: uppercase;
		    width: 110px;
		    font-family: "Flama", sans-serif;
		}
	}

	@include xs-and-sm {

		margin-bottom: 60px;

	}	

	@include xs-only {

		.balance {
			margin: 32px 0;
			padding: 0;
			border: 0;
			width: 100%;

			/* Force table to not be like tables anymore */
			table, thead, tbody, th, td, tr { 
				display: block; 
			}
			
			/* Hide table headers (but not display: none;, for accessibility) */
			thead tr { 
				position: absolute;
				top: -9999px;
				left: -9999px;
			}

			table tbody tr { 
				padding: 10px 0;
				border-bottom: 0;
				&:last-child { border: 1px solid #999; }
				&.no-order {
					padding: 0;

					td {
						padding: 30px !important;
						line-height: 30px !important;
						text-align: center !important;

						&:nth-of-type(1):before { 
							content: ""; 
							display: none;							
						}
					}
				}
			}
			
			td { 
				/* Behave  like a "row" */
				border: none;
				position: relative;
				padding: 9px 15px 9px 50% !important;
				text-align: left !important; 
				font-size: 14px !important;
				line-height: 18px !important;
				
				&:before { 
					/* Now like a table header */
					position: absolute;
					/* Top/left values mimic padding */
					top: 10px;
					left: 18px;
					width: 45%; 
					padding-right: 10px; 
					white-space: nowrap;
					font-family: "Flama Condensed Basic", "Flama";
					font-size: 17px;
				}
			}
			
			/*
			Label the data
			*/
			td:nth-of-type(1):before { content: "DATE"; }
			td:nth-of-type(2):before { content: "RETAILER"; }
			td:nth-of-type(3):before { content: "TRANS. TYPE"; }
			td:nth-of-type(4):before { content: "VALUE"; }
		}

	}
}
.footer {
	background: #000 none repeat scroll 0 0;
    color: #999;
    min-height: 13.75rem;
    margin-top: 75px;

    a, button {
    	text-decoration: none;
    	text-transform: uppercase;
    	color: inherit;
    	&:hover {
    		color: #fff;
    		text-decoration: none;
    	}
    }

    button {
    	outline: 0 none;
    	padding: 0;
    	background: rgba(0, 0, 0, 0) none repeat scroll 0 center;
    	border: 0 none;
    	-moz-user-select: none;
    	cursor: pointer;
    	font-size: 0.875rem;
    	margin: 0;
    	width: 100%;
    	text-align: left;
    }

	.left-column {
		width: 66.6667%;
		display: inline-block;
	    vertical-align: top;
	}

	.footer .dropdown__menu a, .footer .dropdown__menu a:hover {
	    color: #000;
	}

	.dropdown {
		width: 170px;
	}

	.dropdown__menu__link:hover {
	    background: #f2f2f2 none repeat scroll 0 0;
	}

	.dropdown__icon {
	    position: absolute;
	    right: 0.625rem;
	    top: 0.88125rem;
	}
	.dropdown__icon svg {
	    float: right;
	    height: 1.25rem;
	    margin: 0 0.3125rem;
	    stroke: currentcolor;
	    width: 0.75rem;
	}

	.top-part {
		margin: 1.25rem 0 2.8125rem;
		line-height: 2.8125rem;
		font-size: 0.875rem;

		.u-position-relative { position: relative; }

		.icon--arrow {
		    height: 1.25rem;
		    stroke: #999;
		    width: 0.75rem;
		    vertical-align: text-bottom;
		}

		.footer__nav-list__item {
			display: inline-block;
		    margin: 0 1.25rem 0.41667rem 0;
		    vertical-align: middle;
		}

		.f-dropdown {
			position: absolute;
			left: 0;
			top: -138px;
			list-style: none;
			margin-left: 0;
			outline: 0; 
			width: 100%;
			max-height: 250px;
			height: auto;
			background: #fff;
			border: solid 1px #999;
			font-size: .875rem;
			z-index: 89;
			margin-top: -2px;
			margin-left: 0;
			max-width: 200px;
			display: none;

			&.open { display: block; }

			li {
			    cursor: pointer;
			    font-size: 0.875rem;
			    line-height: 1.125rem;
			    margin: 0;

			    a {
			    	color: #000;
			    	display: block;
    				padding: 0.5rem;
    				text-decoration: none;
    				text-transform: uppercase;
			    }
			}
		}
	}

	.u-hide-from-medium {
	    display: none;
	    margin-top: 1.25rem;
	}

	.bottom-part .footer__nav-list-secondary {
	    color: #666;
	    list-style: outside none none;
	    margin: 0 1.25rem;
	    text-transform: uppercase;
	    font-size: 0.75rem;
	    margin: 0;
	    line-height: 2.8125rem;

	    li {
	    	display: inline-block;	  

			&.westfield-labs-logo svg {
			    height: 2.8125rem;
			    width: 6.875rem;
			}
	    }		    		
	}

	@include sm-and-lg {

		.bottom-part .footer__nav-list-secondary li {
			&:first-child:before { content:none; }
			&:before {
	    		content: "|";
	    		padding: 0 .625rem;
	    	}
		}
	}

	@include xs-and-sm {
		margin-top: 0;

		.top-part { line-height: 1.8; }

		.bottom-part .footer__nav-list-secondary { line-height: 1.8; }
	}

	@include xs-only {
		margin-top: 1px;

		.container { width: auto; }

		.left-column { width: 100%; }


		.dropdown {
			width: 160px;
		}

		button {
			font-size: 0.8175rem;
		}

		.top-part {
			margin: 1.25rem 1.875rem 0;
			font-size: 0.8175rem;

			.icon--arrow {
			    vertical-align: text-top;
			}

			.footer__nav-list__item {
				width: 45%;

				&:nth-child(2n+1) {
		    		width: 55%;
		    	}
		    }
		}

		.u-hide-from-medium { display: block; }

		.bottom-part {
			padding-bottom: 1.25rem;
			.footer__nav-list-secondary {
				margin: 0 1.25rem;
			    font-size: 0.6875rem;
			    line-height: 2;

			    li {
					width: 45%;
					margin: 0.125rem 0;

					&:nth-child(2n+1) {
			    		width: 55%;
			    	}

			    	&.westfield-labs-logo {
					    display: block;
					    margin: 0.625rem 0;
					    text-align: center;
					    width: 100%;
					}
			    }
			}
		}
	}
}
@import "toolkit";

.page-home {
	flex-grow: 1;
	
	.big-banner {
		min-height: 404px;
		position: relative;		

		.background {
	    	position: absolute;
	    	top: 0;
	    	left: 0;			
		}

	   	.overlay {
	   		padding: 100px 0 120px;
	   		position: relative;
	   		z-index: 2;

	   		.overlay__inner {
		   		display: inline-block;
		   		text-align: center;
		   	}

	    	h2 {
	    		margin: 0;
	    		font-size: 60px;
	    		line-height: 70px;
	    		text-transform: uppercase;
	    		font-family: "Flama Condensed Light", sans-serif;
	    		font-weight: normal;
	    	}

	    	h3 {
	    		margin: 0 0 10px;
	    		font-size: 23px;
	    		line-height: 40px;
	    		text-transform: uppercase;
	    		font-family: "Flama Light", sans-serif;
	    		font-weight: normal;
	    	}

	    	.button {
	    		font-family: "Flama Light", sans-serif;
	    		font-size: 20px;
	    		padding: 0 46px;
	    		line-height: 52px;
	    		border: 1px solid #000;
	    		color: #000;
	    		text-decoration: none;
	    		display: inline-block;
                -webkit-transition: all 0.2s ease 0s;
                transition: all 0.2s ease 0s;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
	    	}
	    }
    }

    .boxes {
    	margin-top: 65px;
    	overflow: hidden;

    	.row { margin: 0 -2px; }
    	
    	.long-box, .short-box { 
    		padding: 2px; 
    		float: left;
    		display: block;
    		position: relative;
    		text-decoration: none;
    	}
    	
    	.long-box { width: 66.66%; }
    	
    	.short-box { width: 33.33%; }
    	
    	.background-wrapper { 
    		width: 100%; 
    		height: 300px;
    		position: relative;
    	}
    	
    	.overlay {
    		position: absolute;
    		top: 70px;
    		left: 64px;
    		color: #fff;
    		
    		label {
    			background-color: #000;
    			padding: 0 22px;
    			line-height: 43px;
    			height: 43px;
    			font-family: "Flama Condensed Light", sans-serif;
    			font-size: 30px;    
    			display: inline-block;			
    		}
    		
    		h2 {
	    		margin: 10px 0 0;
	    		font-size: 49px;
	    		line-height: 43px;
	    		text-transform: uppercase;
	    		font-family: "Flama Condensed Light", sans-serif;
	    		font-weight: normal;
	    	}
	    	
	    	h3 {
	    		margin: 3px 0 0;
	    		font-size: 35px;
	    		line-height: 43px;
	    		text-transform: uppercase;
	    		font-family: "Flama Condensed Light", sans-serif;
	    		font-weight: normal;
	    	}
    	}

    	.tile__content { 
    		background-color: #000;
    		height: 70px;
    		padding: 14px 20px;
    		color: #fff;
    		display: flex;
    		
    		span { 
    			font-size: 30px;
    			margin-right: 15px;

    			&.icon-promo {
    				font-size: 36px;
    				margin-right: 9px;
    			}

    			&.icon-corporate {
    				font-size: 40px;
    				margin-right: 7px;
    			}
    		}
    		
    		.tile__content__inner-left { 

    			padding: 7px 0; 

    			&.icon-promo-wrapp {
    				padding: 5px 0; 
    			}

    			&.icon-corporate-wrapp {
    				padding: 4px 0;
    			}

    		}
    		
    		.tile__content__inner-right {
    			font-size: 25px;
    			line-height: 25px;
    			text-transform: uppercase;
    			font-family: "Flama Condensed Light", sans-serif;
    			display: flex;
    			align-items: center;
                &:hover {
                    text-decoration: underline;
                }
    		}
    	}
    }

    @include xs-and-sm {

    	.big-banner {
            .background {
                background-position: center right;
            }
            .overlay {
        		padding: 30px 0 0;
        		
        		.container { text-align: center; }
        		
        		.overlay__inner {
        			background-color: rgba(0, 0, 0, 0.6);
        			color: #fff;
        			padding: 20px 30px 25px;
        		}
        		
        		.button {
        			background-color: #fff;
        			border-color: #fff;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.2);
                        color: #fff;
                    }
        		}
        	}
        	
        	.boxes {
        		margin-top: 0;
        		
        		.container { width: auto; }
        		
        		.long-box, .short-box { padding: 1px; }
        		
        		.overlay {
    	    		top: auto;
    	    		bottom: 25px;
    	    		left: 30px;
    	    	}
        	}
        }
    }

    @include xs-only {

    	.big-banner {
    		min-height: 222px;
    		
    		.overlay {
	    		padding: 0;
	    		
	    		.container { width: 100%; }
	    		
	    		.overlay__inner { 
	    			padding: 20px 17px 25px; 
	    			display: block;
	    			min-height: 222px;
	    		}
	    		
	    		h2 { 
	    			font-size: 34px; 
	    			line-height: 40px;
	    		}
	    		
	    		h3 { 
	    			font-size: 13px; 
	    			line-height: 30px;
	    		}
	    		
	    		.button {
	    			background-color: #fff;
	    			border-color: #fff;
	    			font-size: 18px;
	    			line-height: 45px;
	    			padding: 0 35px;
	    		}
	    	}
    	}

    	.boxes {
    		
    		.long-box, .short-box { 
    			width: 100%;
    			padding: 0 0 10px;
    		}
    		
    		.background-wrapper { height: 162px; }
    		
    		.overlay {
	    		bottom: 10px;
	    		left: 15px;
                top: 30px;
	    		
	    		label {
	    			padding: 0 18px;
	    			line-height: 35px;
	    			height: 35px;
	    			font-size: 25px;
	    		}
	    		
	    		h2 {
	    			margin: 8px 0 0;
		    		font-size: 35px;
		    		line-height: 30px;
		    	}
		    	
		    	h3 {
		    		font-size: 25px;
		    		line-height: 30px;
		    	}
	    	}

    		.tile__content { 
	    		height: 50px;
	    		padding: 4px 20px;
	    	}
    	}
    }
}
@import "toolkit";

.mfp-bg { 
	background-color: #000 !important;
}

.mfp-content {
	padding: 0 50px;

	.mfp-counter {
		display: none;
	}

	.mfp-close {
		display: none;
	}
}

.mfp-figure::after {
	background: transparent none repeat scroll 0 0;
	box-shadow: none;
}
.mfp-arrow {
    height: 163px;
    width: 30px;
    display: none;

	&.mfp-arrow-left {
		background-image: url('../img/inner/arrow-popup-left.png') !important;
		background-repeat: no-repeat !important;
	}

	&.mfp-arrow-right {
		background-image: url('../img/inner/arrow-popup-right.png') !important;
		background-repeat: no-repeat !important;
	}

	&::before, &::after, &.mfp-b, &.mfp-a {
		display: none !important; 
	}

	&:hover {
		background-color: transparent !important; 
	}
}


.page-how-to {
	flex-grow: 1;

	.text-section {

		h1 {
			margin-bottom: 7px !important;
		}

		label {
			display: inline-block;
		    margin-bottom: 10px;
		    margin-right: 42px;
		    font-weight: normal;
		}

		.order__now {
			background-color: $color-red;
		    border: 0 none;
		    color: #fff !important;
		    font-size: 16px;
		    height: 50px;
		    line-height: 48px;
		    text-align: center;
		    text-decoration: none;
		    text-transform: uppercase;
		    display: inline-block;
		    width: 136px;
		    font-family: "Flama";
	    	-webkit-transition: all 0.2s ease 0s;
		    transition: all 0.2s ease 0s;
    		&:hover {
    			background-color: #c82127;
    		}
		}

		.image-grid {

			display: block;
			float: left;
			position: relative;
			margin-right: 3%;
			width: 48.5%;
			margin-bottom: 20px !important;

			&:nth-child(2n) { margin-right: 0; }

			img {
				float: left;
				width: 100% !important;
				max-width: 100%;
				cursor: pointer;
				margin-bottom: 0px !important;
			}

			.overlay {
				height: 100%;
			    opacity: 0;
			    padding: 0;
			    position: absolute;
			    text-align: center;
			    top: 0;
			    transition: all 0.2s ease-in 0s;
			    -webkit-transition: all 0.2s ease-in 0s;
			    -moz-transition: all 0.2s ease-in 0s;
			    -ms-transition: all 0.2s ease-in 0s;
			    -o-transition: all 0.2s ease-in 0s;
			    width: 100%;

				.overlay-background {
				    background-color: rgba(0, 0, 0, 0.8);
				    height: 100%;
				    width: 100%;
				}

				i.icon {
					color: #ffffff;
					font-size: 66px;
					left: 50%;
					margin-left: -34px;
					margin-top: -30px;
					position: absolute;
					top: 50%;
				}
			}

			a:hover {
				text-decoration: none;

				.overlay {
					opacity: 1;
				}
			}
		}
	}
	.sidebar {
		.tile__content {
			padding-left: 12px !important;
			padding-right: 12px !important;

			.tile__content__inner-left {
				padding: 3px 0 !important;
			}

			span {
				width: 44px !important;
				font-size: 38px !important;
			}
		}

		.list__box {
			background-color: #ddd;
			display: block;
		    float: left;
		    width: 100%;

			ul {
				list-style: none;
				padding: 16px 24px;

				li {
					list-style: none;
					padding-bottom: 13px;


					a {
						text-decoration: none;
						color: $color-red;
						font-family: Arial;
						font-size: 15px;
					}
				}
				li:last-child {
					padding-bottom: 0;
				}
			}
		}
	}

	@include xs-and-sm {

		label {
			margin-right: 0px;
			margin-bottom: 0px !important;
		}

		.order__now {
			display: block !important;
		    margin-bottom: 23px;
		    margin-left: 0 !important;
		    margin-top: 15px;
		}

	}

	@include xs-only{
		.order__now {
		    margin-bottom: 17px;
		    margin-top: 13px;
		}

		.image-grid {
			width: 293px !important;
		}
	}

}
@import "toolkit";

.page-inner {
	flex-grow: 1;

	&.page-corporate .content-with-sidebar .text-section > p:first-child { margin-top: 0; }
	&.page-faq .content-with-sidebar .text-section .first h3 { margin-top: 0px; }
	&.page-contact .content-with-sidebar .text-section > p:first-child { margin-top: 0; }
	&.page-card .content-with-sidebar .text-section > p:first-child { margin-top: 0; }

	.content-with-sidebar {
		padding-top: 80px;
		position: relative;

		.banner {
			float: right;
			width: 185px;
			margin: 0 8px;

			.background{
				height: 612px;
			}
		}

		.mfp-first .mfp-arrow-left { display: none; }

		.mfp-last .mfp-arrow-right { display: none; }

		.text-section {
			max-width: -moz-calc(100% - 520px);
			max-width: -webkit-calc(100% - 520px);
		    max-width: calc(100% - 520px);
			display: inline-block;
		    margin: 0 0 0 30px;
		    padding: 0 0 30px;
		    width: 620px;

		    h1 {
				font-family: "Flama Light", sans-serif;
				font-weight: normal;
				font-size: 30px;
				line-height: 30px;
				color: #29292b;
				text-transform: capitalize;
				margin: 0 0 30px;
			}

			h3 {
				font-size: 18px;
				font-family: "Flama";
				line-height: 20px;
				margin-bottom: 0px;
				margin-top: 20px;
			}

			p {
			 	font-family: Arial;
			 	font-weight: normal;
				font-size: 15px;
				line-height: 20px;
				margin-top: 10px;
			}

			a {
				text-decoration: none;
				color: #c81218;
			}

			img { 
				margin-bottom: 20px; 
				max-width: 100%;
				display: block;
			}

			ul{
				font-family: Arial;
			 	font-size: 15px;
				line-height: 20px;
				list-style: inside;
				color: $color-red;

				li{
					list-style: inside;
					color: $color-red;
				}

				span { color: #000; }
			}

			.image-section{
				display: table;
				margin-top: 5px;

				.left__title{
					display: inline-block;
				    text-align: center;
				    width: 49%;
				    margin-bottom: 0px;
				}

				.right__title{
					display: inline-block;
				    text-align: center;
				    width: 49%;
				    margin-bottom: 0px;
				}

				img{
					margin-bottom: 5px;
					width: 100%;
				}
			}

			.order__now {
				background-color: $color-red;
			    border: 0 none;
			    color: #fff !important;
			    font-size: 16px;
			    height: 50px;
			    line-height: 48px;
			    text-align: center;
			    text-decoration: none;
			    text-transform: uppercase;
			    display: inline-block;
			    width: 136px;
			    font-family: "Flama";
		    	-webkit-transition: all 0.2s ease 0s;
			    transition: all 0.2s ease 0s;
	    		&:hover {
	    			background-color: #c82127;
	    		}
			}
		}

		.sidebar {
			float: left;
			width: 280px;
			max-width: 100%;
			font-family: Arial;
			font-size: 14px;

			.background {
				height: 212px;
			    position: relative;
			    width: 100%;
			}	
			.short-box { 
	    		float: left;
	    		display: block;
	    		position: relative;
	    		text-decoration: none;
	    		width: 100%;
			}

			.tile__content { 
	    		background-color: #000;
	    		height: 70px;
	    		padding: 14px 30px;
	    		color: #fff;
	    		display: flex;
	    		
	    		span { 
	    			font-size: 22px;
	    			width: 48px;
	    			display: inline-block;
	    		}
	    		
	    		.tile__content__inner-left { padding: 10px 0; }
	    		
	    		.tile__content__inner-right {
	    			font-size: 25px;
	    			line-height: 32px;
	    			text-transform: uppercase;
	    			font-family: "Flama Condensed Light", sans-serif;
	    			display: flex;
	    			align-items: center;
	    		}	    		
			}

			a .tile__content__inner-right:hover {
    			text-decoration: underline;
    		}

			.short-box-info {
				width: 100%;
				float: left;
	    		display: block;
	    		position: relative;
	    		margin: 35px 0;

	    		.tile__content {
	    			height: auto;
	    			padding-bottom: 6px;

	    			.tile__content__inner-left { padding: 5px 0; }
	    		}

	    		.details__content {
	    			background-color: #000;
	    			padding: 0px 38px 17px 78px;
	    			height: auto;
	    			color: rgba(255,255,255,0.5);
	    			font-size: 14px;
	    			font-family: "Arial";
	    			line-height: 20px;

	    			label { font-size: 11px; }
	    		}
			}
		}

		@include xs-and-sm {
			padding-top: 40px;
			
			.banner { display: none; }

			.text-section {
				max-width: -moz-calc(100% - 252px);
				max-width: -webkit-calc(100% - 252px);
			    max-width: calc(100% - 252px);
			    width: auto;
			}

			.sidebar { 
				width: 220px;

				.background { height: 166px; }

				.tile__content { 
					padding: 14px 20px;

					span { width: 40px; }
				}

				.short-box-info .details__content { padding: 0px 20px 17px 60px; }
			}
		}

		@include xs-only {
			padding-top: 20px;

			.text-section { 
				max-width: 100%; 
				margin-left: 0;
				padding: 0 0 15px;

				h1 {
					font-size: 25px;
					line-height: 30px;
					text-align: center;
					margin: 0 auto 20px;
				    max-width: 90%;			 
				}

				img { margin: 0 auto 20px; }
			}

			.sidebar { 
				width: 100%;
				margin-bottom: 20px;

				.background { display: none; }

				.short-box-info { margin: 12px 0; }
			}
		}
	}
}

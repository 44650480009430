@charset "UTF-8";

@font-face {
  font-family: "westfield";
  src:url("../fonts/CustomFonts/westfield.eot");
  src:url("../fonts/CustomFonts/westfield.eot?#iefix") format("embedded-opentype"),
    url("../fonts/CustomFonts/westfield.woff") format("woff"),
    url("../fonts/CustomFonts/westfield.ttf") format("truetype"),
    url("../fonts/CustomFonts/westfield.svg#westfield") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "westfield" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "westfield" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-balance:before {
  content: "b";
}
.icon-call:before {
  content: "p";
}
.icon-card-change:before {
  content: "f";
}
.icon-card-landscape:before {
  content: "g";
}
.icon-card-portrait:before {
  content: "h";
}
.icon-creditcard:before {
  content: "i";
}
.icon-location:before {
  content: "l";
}
.icon-email:before {
  content: "m";
}
.icon-edit-s:before {
  content: "j";
}
.icon-edit-c:before {
  content: "k";
}
.icon-nocard:before {
  content: "n";
}
.icon-zoom:before {
  content: "o";
}
.icon-tick-c:before {
  content: "d";
}
.icon-tick:before {
  content: "a";
}
.icon-shipping-rural:before {
  content: "q";
}
.icon-remove-s:before {
  content: "t";
}
.icon-shipping-standard:before {
  content: "u";
}
.icon-remove:before {
  content: "v";
}
.icon-promo:before {
  content: "s";
}
.icon-order:before {
  content: "c";
}
.icon-remove-c:before {
  content: "x";
}
.icon-edit:before {
  content: "e";
}
.icon-corporate:before {
  content: "r";
}


//
//  ╔════════════╗
//  ║ BREAKPOINS ║
//  ╚════════════╝
//

$screen-sm-min: 640px;
$screen-lg-min: 1024px;
$screen-xs-max: $screen-sm-min - 1px;
$screen-md-max: $screen-lg-min - 1px;

$screen-print-xs-max: 580px;

// only dekstop
@mixin lg-only {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// tablet and dekstop
@mixin sm-and-lg {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// only tablet
@mixin sm-only {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}


// mobile and tablet
@mixin xs-and-sm {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// only mobile
@mixin xs-only {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

// only mobile
@mixin xs-print-only {
  @media (max-width: #{$screen-print-xs-max}) {
    @content;
  }
}


//
//  ╔════════╗
//  ║ COLORS ║
//  ╚════════╝
//

$color-dark: #29292b;
$color-red: #da291c;
$color-border: #999;